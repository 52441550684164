import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import AlertMessage from "../components/AlertMessage";
import { CharacterImageUrls } from "../types/characterTypes";
import { SceneImageUrls } from "../types/sceneTypes";
import {
  ExtendedScriptLineWithSceneId,
  ScriptLinesWithSceneId,
} from "../types/scriptTypes";
import StoryViewer from "../components/viewer/StoryViewer";
import { useAuth } from "@clerk/clerk-react";

const StoryViewerPage = () => {
  const { getToken } = useAuth();
  const [isPlaying, setIsPlaying] = useState(false);
  const [scriptOnlyMode, setScriptOnlyMode] = useState(false);
  const [alert, setAlert] = useState("");
  const [audioFiles, setAudioFiles] = useState({
    music: "",
    soundscape: "",
  });

  const { storyId } = useParams<{ storyId: string }>();

  const [scriptLines, setScriptLines] =
    useState<ExtendedScriptLineWithSceneId[]>();
  const [characterImageUrls, setCharacterImageUrls] =
    useState<CharacterImageUrls>();
  const [sceneImageUrls, setSceneImageUrls] = useState<SceneImageUrls>();

  const fetchStory = async () => {
    try {
      const token = await getToken();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/stories/${storyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      const formattedData: ExtendedScriptLineWithSceneId[] =
        data.script_lines.flatMap((scene: ScriptLinesWithSceneId) =>
          scene.scriptLines.map((line) => ({
            ...line,
            sceneId: scene.sceneId,
          })),
        );

      setScriptLines(formattedData);
      setCharacterImageUrls(data.character_image_urls);
      setSceneImageUrls(data.scene_image_urls);
    } catch (error) {
      console.error("Error fetching story data", error);
      setAlert("Error fetching story data");
    }
  };

  const fetchMusic = async () => {
    try {
      const musicPath = `generation_id_${storyId}/music.mp3`;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/get-audio`,
        {
          storyId,
          audioPath: musicPath,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );

      let alertsMsg = "";
      const result = response.data.audio_url;
      if (!result) alertsMsg += "No Music found! ";
      if (alertsMsg) setAlert(alertsMsg.trim());

      setAudioFiles((prev) => ({ ...prev, music: result }));
    } catch (error) {
      console.error("Error fetching music file", error);
      setAlert("Error fetching music file");
    }
  };

  const fetchSoundscape = async () => {
    try {
      const soundscapePath = `generation_id_${storyId}/soundscape.mp3`;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/get-audio`,
        {
          storyId,
          audioPath: soundscapePath,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );

      let alertsMsg = "";

      const result = response.data.audio_url;
      if (!result) alertsMsg += "No Soundscape found! ";
      if (alertsMsg) setAlert(alertsMsg.trim());

      setAudioFiles((prev) => ({ ...prev, soundscape: result }));
    } catch (error) {
      console.error("Error fetching music file", error);
      setAlert("Error fetching music file");
    }
  };

  useEffect(() => {
    if (storyId) {
      fetchStory();
      fetchMusic();
      fetchSoundscape();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyId]);

  return (
    <Box>
      {alert && <AlertMessage severity="error" message={alert} />}

      <StoryViewer
        scriptLines={scriptLines}
        characterImageUrls={characterImageUrls}
        sceneImageUrls={sceneImageUrls}
        audioFiles={audioFiles}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        scriptOnlyMode={scriptOnlyMode}
        setScriptOnlyMode={setScriptOnlyMode}
      />
    </Box>
  );
};

export default StoryViewerPage;
