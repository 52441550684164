import StoryDetailsAccordionWrapper from "../StoryDetailsAccordionWrapper";

interface StoryOutlinesAccordionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any[];
}

const StoryOutlinesAccordion = ({ content }: StoryOutlinesAccordionProps) => {
  return (
    <>
      {content.map((storyOutline) => {
        return (
          <StoryDetailsAccordionWrapper
            key={storyOutline.num}
            title={storyOutline.title ? storyOutline.title : "Story Outline"}
          >
            {storyOutline.description}
          </StoryDetailsAccordionWrapper>
        );
      })}
    </>
  );
};

export default StoryOutlinesAccordion;
