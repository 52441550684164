import { Box } from "@mui/material";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";

const MobilePortraitOverlay = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#121212",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        p: 2,
        zIndex: 2000,
      }}
    >
      <ScreenRotationIcon sx={{ fontSize: 80, color: "white", mb: 3 }} />
      <Box sx={{ color: "white", fontSize: "1rem" }}>
        If viewing on mobile please switch to landscape mode or expand your
        window on desktop.
      </Box>
    </Box>
  );
};

export default MobilePortraitOverlay;
