import { Box, Typography } from "@mui/material";
import CharacterDetail from "./CharacterDetail";
import { Character } from "../types/characterTypes";

interface CharacterDetailsProps {
  character: Character;
}

const CharacterDetails = ({ character }: CharacterDetailsProps) => {
  return (
    <Box sx={{ color: "rgba(255, 255, 255, 0.7)", textAlign: "left" }}>
      <Typography variant="body2">
        <CharacterDetail>Backstory:</CharacterDetail>{" "}
        {character.background.backstory}
        <br />
        <CharacterDetail>Core Values:</CharacterDetail>{" "}
        {character.background.coreValues}
        <br />
        <CharacterDetail>Fears and Flaws:</CharacterDetail>{" "}
        {character.background.fearsAndFlaws}
        <br />
        <CharacterDetail>Motivations:</CharacterDetail>{" "}
        {character.background.motivations}
        <br />
        <CharacterDetail>Description:</CharacterDetail> {character.description}
        <br />
        <CharacterDetail>Location:</CharacterDetail> {character.location}
        <br />
        <CharacterDetail>Name:</CharacterDetail> {character.name}
        <br />
        <CharacterDetail>Script Name:</CharacterDetail> {character.scriptName}
        <br />
        <CharacterDetail>Character Arc Goal:</CharacterDetail>{" "}
        {character.narrativeRole.characterArcGoal}
        <br />
        <CharacterDetail>Symbolic Role:</CharacterDetail>{" "}
        {character.narrativeRole.symbolicRole}
        <br />
        <CharacterDetail>Narrative Tropes:</CharacterDetail>{" "}
        {character.narrativeTropes}
        <br />
        <CharacterDetail>Age:</CharacterDetail> {character.physicalProfile.age}
        <br />
        <CharacterDetail>Appearance:</CharacterDetail>{" "}
        {character.physicalProfile.appearance}
        <br />
        <CharacterDetail>Gender:</CharacterDetail>{" "}
        {character.physicalProfile.gender}
        <br />
        <CharacterDetail>Specific Traits:</CharacterDetail>{" "}
        {character.physicalProfile.specificTraits}
        <br />
        <CharacterDetail>Emotional Disposition:</CharacterDetail>{" "}
        {character.psychologicalProfile.emotionalDisposition}
        <br />
        <CharacterDetail>Intellectual Style:</CharacterDetail>{" "}
        {character.psychologicalProfile.intellectualStyle}
        <br />
        <CharacterDetail>Moral Alignment:</CharacterDetail>{" "}
        {character.psychologicalProfile.moralAlignment}
        <br />
        <CharacterDetail>Personality:</CharacterDetail>{" "}
        {character.psychologicalProfile.personality}
        <br />
        <CharacterDetail>Strengths:</CharacterDetail>{" "}
        {character.psychologicalProfile.strengths}
        <br />
        <CharacterDetail>Style of Speech:</CharacterDetail>{" "}
        {character.psychologicalProfile.styleOfSpeech}
        <br />
        <CharacterDetail>Weaknesses:</CharacterDetail>{" "}
        {character.psychologicalProfile.weaknesses}
        <br />
        <CharacterDetail>Purpose:</CharacterDetail> {character.purpose}
        <br />
        <CharacterDetail>Relevance:</CharacterDetail> {character.relevance}
        <br />
        <CharacterDetail>Role:</CharacterDetail> {character.role}
        <br />
        <CharacterDetail>Setting Tropes:</CharacterDetail>{" "}
        {character.settingTropes}
        <br />
        <CharacterDetail>Species:</CharacterDetail> {character.species}
        <br />
        <CharacterDetail>Type:</CharacterDetail> {character.type}
        <br />
        <CharacterDetail>Visual Description:</CharacterDetail>{" "}
        {character.visualDescription}
        <br />
        <CharacterDetail>Key Relationships:</CharacterDetail>
        <br />
        {character.relationalDynamics.keyRelationships.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (relationship: any, relIndex: number) => (
            <span key={relIndex}>
              <CharacterDetail>- Script name:</CharacterDetail>{" "}
              {relationship.scriptName}{" "}
              <CharacterDetail>Relationship:</CharacterDetail>{" "}
              {relationship.relationship}
              <br />
            </span>
          ),
        )}
        <CharacterDetail>Reputation:</CharacterDetail>{" "}
        {character.relationalDynamics.reputation}
        <br />
      </Typography>
    </Box>
  );
};

export default CharacterDetails;
