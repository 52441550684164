import StoryDetailsAccordionWrapper from "../StoryDetailsAccordionWrapper";
import SceneDetails from "../../../SceneDetails";

interface ScenesAccordionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any[];
}

const ScenesAccordion = ({ content }: ScenesAccordionProps) => {
  return (
    <>
      {content.map((scene) => {
        return (
          <StoryDetailsAccordionWrapper
            key={scene.sceneId}
            title={"Scene " + scene.sceneId}
          >
            <SceneDetails scene={scene} />
          </StoryDetailsAccordionWrapper>
        );
      })}
    </>
  );
};

export default ScenesAccordion;
