import { ReactNode, MouseEvent, useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Typography,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import LoadingStateIcon from "./LoadingStateIcon";

export interface BaseAccordionProps {
  children: ReactNode;
  title: string;
  isEditingDisabled: boolean;
  isLoading: boolean;
  isEditing?: boolean;
  onSave?: (e: MouseEvent<HTMLButtonElement>) => void;
  toggleEdit?: () => void;
  enableEditingFeatures?: boolean;
}

const BaseAccordion = ({
  children,
  title,
  isEditingDisabled = false,
  isLoading,
  isEditing = false,
  onSave,
  toggleEdit,
  enableEditingFeatures = false,
}: BaseAccordionProps) => {
  const [highlight, setHighlight] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setHighlight(true);
      const timer = setTimeout(() => setHighlight(false), 500);
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  const getAccordionBackground = () =>
    highlight ? "rgba(40, 216, 169, 0.2)" : "rgba(102, 102, 102, 0.4)";

  const toggleAccordion = (e: MouseEvent) => {
    e.stopPropagation();
    if (!isEditing && !isLoading) setIsExpanded((prev) => !prev);
  };

  return (
    <Accordion
      expanded={isExpanded}
      sx={{
        backgroundColor: isEditingDisabled
          ? "rgba(102, 102, 102, 0.4)"
          : getAccordionBackground(),
        marginBottom: "10px",
        borderRadius: "4px",
        overflow: "hidden",
        "&:before": { display: "none" },
        transition: "background-color 0.5s ease",
      }}
    >
      <AccordionSummary
        sx={{
          minHeight: "60px",
          cursor: isLoading ? "not-allowed" : "pointer",
          position: "relative",
        }}
        onClick={toggleAccordion}
      >
        <Box>
          <Typography variant="accordionTitle">{title}</Typography>
        </Box>

        {isLoading && (
          <Box sx={{ ml: 1 }}>
            <LoadingStateIcon isDataLoading={isLoading} showTick={false} />
          </Box>
        )}

        <Box
          sx={{
            position: "absolute",
            right: "10px",
            bottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {enableEditingFeatures &&
            !isEditingDisabled &&
            onSave &&
            toggleEdit && (
              <>
                {isExpanded && !isEditing && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleEdit();
                    }}
                    size="small"
                    sx={{ color: "text.primary" }}
                  >
                    <EditIcon />
                  </IconButton>
                )}

                {isEditing && (
                  <>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onSave(e);
                      }}
                      disabled={isLoading}
                      sx={{ color: "text.primary" }}
                    >
                      {isLoading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <SaveIcon />
                      )}
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleEdit();
                      }}
                      size="small"
                      disabled={isLoading}
                      sx={{ color: "text.primary" }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </>
                )}
              </>
            )}

          <Box
            sx={{
              width: 22,
              height: 22,
              borderRadius: "50%",
              border: "3px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "transform 0.3s ease",
              transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
              marginLeft: "8px",
            }}
          >
            <ExpandMoreIcon sx={{ color: "#fff", fontSize: "16px" }} />
          </Box>
        </Box>
      </AccordionSummary>

      <Divider
        sx={{
          borderTop: "1px solid #FFFFFF",
          marginBottom: "0px",
        }}
      />

      <AccordionDetails sx={{ padding: "16px" }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default BaseAccordion;
