import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import StarFavourite from "./StarFavourite";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";

type PreviewBoxProps = {
  storyId: string;
  title: string;
  createdAt: string;
};

const PreviewBox = ({ storyId, title, createdAt }: PreviewBoxProps) => {
  const navigate = useNavigate();
  const { getToken } = useAuth();

  const [backgroundUrl, setBackgroundUrl] = useState("");

  const date = new Date(createdAt);

  const formattedDate = date.toLocaleString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  const fetchBackgroundImage = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/stories/${storyId}/any-scene-image`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      if (response.data.url) {
        setBackgroundUrl(response.data.url);
      }
    } catch (error) {
      console.error(
        "Something went wrong while loading background image!",
        error,
      );
    }
  };

  useEffect(() => {
    fetchBackgroundImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      onClick={() =>
        navigate(`/story/${storyId}`, {
          state: { title, storyId, backgroundUrl },
        })
      }
      sx={{
        position: "relative",
        width: "calc(25% - 16px)",
        height: "180px",
        minWidth: "300px",
        backgroundImage: `url(${backgroundUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "top center",
        borderRadius: "8px",
        overflow: "hidden",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        color: "white",
        cursor: "pointer",
        "::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "linear-gradient(to right, black, transparent)",
          borderRadius: "8px",
          border: "2px solid black",
          zIndex: 1,
        },
      }}
    >
      <StarFavourite />
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
      </Box>
      <Box sx={{ textAlign: "right", fontSize: "10px" }}>{formattedDate}</Box>
    </Box>
  );
};

export default PreviewBox;
