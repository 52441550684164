import axios from "axios";

// Get the locations for the edit
const getLocations = async (token: string, storyId: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/stories/${storyId}/locations`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data.locations;
  } catch (err) {
    console.error("Error fetching locations:", err);
  }
};

export default getLocations;
