import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth } from "@clerk/clerk-react";
import SectionWrapper from "./SectionWrapper";
import startCharacterBlueprintGeneration from "../../../../utils/startCharacterBlueprintGeneration";
import CharacterDetails from "../../../CharacterDetails";
import { Character } from "../../../../types/characterTypes";
import { useStoryGenerationContext } from "../../../../context/StoryGenerationContext";
import GenerationStageAccordionWrapper from "../GenerationStageAccordionWrapper";
import CharacterDetail from "../../../CharacterDetail";
import axios from "axios";
import getLocations from "../../../../utils/getLocations";
interface CharactersStageProps {
  isEditingDisabled: boolean;
  handleNext?: () => Promise<void>;
  storyId?: string;
  setAlert?: (alertMsg: string) => void;
}

const CharactersStage = ({
  isEditingDisabled,
  handleNext,
  storyId,
  setAlert,
}: CharactersStageProps) => {
  const { getToken } = useAuth();
  const { storyGenerationState, updateStoryGenerationState } =
    useStoryGenerationContext();

  const [loading, setLoading] = useState(true);

  const [editedCharacters, setEditedCharacters] = useState<Character[]>([]);
  const [locationSelect, setLocationSelect] = useState<string[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const characters =
    storyGenerationState.data?.find((state) => state.section === "Characters")
      ?.value || [];

  useEffect(() => {
    // When characters are loaded create a copy for the edit
    if (characters && characters.length !== 0) {
      setEditedCharacters(() =>
        characters.map((character: Character) => {
          // Use structuredClone to deep clone the character
          const clonedCharacter = structuredClone(character);

          // Add or modify properties after cloning
          clonedCharacter.isEditing = false;

          return clonedCharacter;
        }),
      );
      setLoading(false);

      // load locations for edit select
      const loadData = async () => {
        if (storyId) {
          const token = (await getToken()) as string;

          const result = await getLocations(token, storyId);
          setLocationSelect(result);
        }
      };

      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [characters]);

  const handleToggleEdit = (characterId: string) => {
    // Toggles edit on and off
    // when off shows the original character
    setEditedCharacters((editedCharacters) =>
      editedCharacters.map((editedCharacter) => {
        if (editedCharacter.characterID === characterId) {
          const originalCharacter = characters.find(
            (b: Character) => b.characterID === characterId,
          );

          if (!originalCharacter) {
            throw new Error(`Character with id: ${characterId} not found.`);
          }

          return {
            ...originalCharacter,
            isEditing: !editedCharacter.isEditing,
          };
        }
        return editedCharacter;
      }),
    );
  };

  const handleChange = (
    characterID: string,
    keyPath: string,
    value: string,
  ) => {
    setEditedCharacters((prevCharacters) =>
      prevCharacters.map((char) => {
        if (char.characterID !== characterID) return char; // Keep other characters unchanged

        // console.log({ characterID });
        // console.log({ keyPath });
        // console.log({ value });
        // Create a deep clone of the character object using structuredClone as character is a nested object
        const updatedCharacter = structuredClone(char);

        // split the key path into an array
        const keys = keyPath.split(".");

        // TODO:  below **** as Record<string, any>; **** Overrides type safety
        // typescript doesn't allow dynamic access
        // could use lodash set instead?
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let current = updatedCharacter as Record<string, any>;

        console.log({ current });
        // loop through the key path to find the correct value to update
        // with the loop it moves deeper into the nested object
        for (let i = 0; i < keys.length - 1; i++) {
          console.group("current[keys[i]]", current[keys[i]]);
          current = current[keys[i]];
        }

        console.log(
          "current[keys[keys.length - 1]]",
          current[keys[keys.length - 1]],
        );
        // Update the final field at the end of the path
        current[keys[keys.length - 1]] = value;

        return updatedCharacter;
      }),
    );
  };

  const handleSaveEditedCharacter = async (id: string) => {
    if (!editedCharacters) {
      return;
    }

    setLoading(true);
    const token = await getToken();

    // find the edited character
    const editedCharacter = editedCharacters.find(
      (character) => character.characterID === id,
    );

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-character`,
        {
          storyId,
          character: editedCharacter,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      // THIS WILL RETURN ALL OF THE CHARACTERS
      const updated_characters = response.data.updated_characters;

      setEditedCharacters(
        updated_characters.map((character: Character) => ({
          ...character,
          isEditing: false,
        })),
      );

      updateStoryGenerationState({ Characters: updated_characters });
    } catch (error) {
      setAlert?.("Failed to save the character. Please try again.");

      console.error("Error saving character:", error);
    } finally {
      setLoading(false);
    }
  };

  const hasEdits =
    (characters &&
      editedCharacters?.some((character) => character.isEditing)) ??
    false;

  return (
    <SectionWrapper
      canProceed={!hasEdits && !loading}
      handleNext={
        // if handleNext is undefined NEXT button won't show
        // this is important when showing the previous steps
        handleNext && storyId
          ? async () => {
              const token = (await getToken()) as string;
              startCharacterBlueprintGeneration({
                token,
                storyId,
              });
              await handleNext();
            }
          : undefined
      }
    >
      {characters.length === 0 ? (
        <GenerationStageAccordionWrapper
          title="Characters"
          isEditingDisabled={isEditingDisabled}
          isLoading={loading}
        >
          <Typography variant="body2">No characters available yet.</Typography>
        </GenerationStageAccordionWrapper>
      ) : (
        editedCharacters?.map((character: Character) => (
          <GenerationStageAccordionWrapper
            key={character.characterID}
            title={character.name ? character.name : "Characters"}
            isEditingDisabled={isEditingDisabled}
            isEditing={character.isEditing}
            isLoading={loading}
            toggleEdit={() => {
              handleToggleEdit(character.characterID);
            }}
            onSave={() => {
              handleSaveEditedCharacter(character.characterID);
            }}
          >
            {character.isEditing ? (
              <Box sx={{ textAlign: "left" }}>
                <TextField
                  label="Backstory"
                  value={character.background.backstory}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "background.backstory",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Core Values"
                  value={character.background.coreValues}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "background.coreValues",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Fears and Flaws"
                  value={character.background.fearsAndFlaws}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "background.fearsAndFlaws",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Motivations"
                  value={character.background.motivations}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "background.motivations",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Description"
                  value={character.description}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "description",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                {/* <TextField
                  label="Location"
                  value={character.location}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "location",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                 */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Location</InputLabel>
                  <Select
                    value={character.location || ""}
                    onChange={(e) =>
                      handleChange(
                        character.characterID,
                        "location",
                        e.target.value,
                      )
                    }
                    label="Location"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          backgroundColor: "black",
                          color: "white",
                        },
                      },
                    }}
                  >
                    {locationSelect.map((location) => (
                      <MenuItem key={location} value={location}>
                        {location}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Name"
                  value={character.name}
                  onChange={(e) =>
                    handleChange(character.characterID, "name", e.target.value)
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Script Name"
                  value={character.scriptName}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "scriptName",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Character Arc Goal"
                  value={character.narrativeRole.characterArcGoal}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "narrativeRole.characterArcGoal",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Symbolic Role"
                  value={character.narrativeRole.symbolicRole}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "narrativeRole.symbolicRole",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Narrative Tropes"
                  value={character.narrativeTropes}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "narrativeTropes",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Age"
                  value={character.physicalProfile.age}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "physicalProfile.age",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Appearance"
                  value={character.physicalProfile.appearance}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "physicalProfile.appearance",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Gender"
                  value={character.physicalProfile.gender}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "physicalProfile.gender",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Specific Traits"
                  value={character.physicalProfile.specificTraits}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "physicalProfile.specificTraits",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Emotional Disposition"
                  value={character.psychologicalProfile.emotionalDisposition}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "psychologicalProfile.emotionalDisposition",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Intellectual Style"
                  value={character.psychologicalProfile.intellectualStyle}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "psychologicalProfile.intellectualStyle",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Moral Alignment"
                  value={character.psychologicalProfile.moralAlignment}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "psychologicalProfile.moralAlignment",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Personality"
                  value={character.psychologicalProfile.personality}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "psychologicalProfile.personality",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Strengths"
                  value={character.psychologicalProfile.strengths}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "psychologicalProfile.strengths",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Style of Speech"
                  value={character.psychologicalProfile.styleOfSpeech}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "psychologicalProfile.styleOfSpeech",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Weaknesses"
                  value={character.psychologicalProfile.weaknesses}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "psychologicalProfile.weaknesses",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Purpose"
                  value={character.purpose}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "purpose",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Relevance"
                  value={character.relevance}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "relevance",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Role"
                  value={character.role}
                  onChange={(e) =>
                    handleChange(character.characterID, "role", e.target.value)
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Setting Tropes"
                  value={character.settingTropes}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "settingTropes",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Species"
                  value={character.species}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "species",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Type"
                  value={character.type}
                  onChange={(e) =>
                    handleChange(character.characterID, "type", e.target.value)
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Visual Description"
                  value={character.visualDescription}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "visualDescription",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Reputation"
                  value={character.relationalDynamics.reputation}
                  onChange={(e) =>
                    handleChange(
                      character.characterID,
                      "relationalDynamics.reputation",
                      e.target.value,
                    )
                  }
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                />{" "}
                <CharacterDetail>Key Relationships:</CharacterDetail>
                <br />
                {character.relationalDynamics.keyRelationships.map(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (relationship: any, relIndex: number) => (
                    <span key={relIndex}>
                      <CharacterDetail>- Script name:</CharacterDetail>{" "}
                      {relationship.scriptName}{" "}
                      <CharacterDetail>Relationship:</CharacterDetail>{" "}
                      {relationship.relationship}
                      <br />
                    </span>
                  ),
                )}
              </Box>
            ) : (
              <>
                <CharacterDetails character={character} />
              </>
            )}
          </GenerationStageAccordionWrapper>
        ))
      )}
    </SectionWrapper>
  );
};

export default CharactersStage;
