import axios from "axios";

interface StartPoseAndExpressionGenerationType {
  storyId: string;
  token: string;
}

const startPoseAndExpressionGeneration = async ({
  storyId,
  token,
}: StartPoseAndExpressionGenerationType) => {
  console.log("Start pose and expression generation!");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/start-pose-and-expression-generation`,
      { storyId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      },
    );
    console.log("Start pose and expression generation response: ", response);
  } catch (error) {
    console.error("Failed to start pose and expression generation");
  }
};

export default startPoseAndExpressionGeneration;
