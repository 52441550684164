import { useEffect, useRef, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import SectionWrapper from "./SectionWrapper";
import { useStoryGenerationContext } from "../../../../context/StoryGenerationContext";
import startMusicAndSoundscape from "../../../../utils/startMusicAndSoundscape";
import checkAudioGenerationState from "../../../../utils/checkAudioGenerationState";
import uploadAudio from "../../../../utils/uploadAudio";
import ScriptLineDetails from "../../../ScriptLineDetails";
import GenerationStageAccordionWrapper from "../GenerationStageAccordionWrapper";

interface ScriptStageProps {
  isEditingDisabled: boolean;
  storyId?: string;
}

const ScriptStage = ({ isEditingDisabled, storyId }: ScriptStageProps) => {
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const { storyGenerationState } = useStoryGenerationContext();

  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scriptData =
    storyGenerationState?.data?.find((state) => state.section === "Script")
      ?.value || [];

  const [sceneImagesReady, setSceneImagesReady] = useState(false);
  const [characterImagesReady, setCharacterImagesReady] = useState(false);

  const sceneIntervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const characterIntervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const scenePollCountRef = useRef(0);
  const characterPollCountRef = useRef(0);

  const musicPollAttemptsRef = useRef(0);
  const soundscapePollAttemptsRef = useRef(0);
  const musicIntervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const soundscapeIntervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const [musicGenerationReady, setMusicGenerationReady] = useState(false);
  const [soundscapeGenerationReady, setSoundscapeGenerationReady] =
    useState(false);
  const [musicGenerationQueryURL, setMusicGenerationQueryURL] = useState("");
  const [soundscapeGenerationQueryURL, setSoundscapeGenerationQueryURL] =
    useState("");

  const MAX_POLL_ATTEMPTS = 30;

  useEffect(() => {
    if (!storyId) return;
    const fetchAndStart = async () => {
      if (scriptData?.length > 0) {
        const token = (await getToken()) as string;
        const audioResponse = await startMusicAndSoundscape({
          token,
          storyId,
        });

        if (audioResponse) {
          const { musicResult, soundscapeResult } = audioResponse;

          // Handle music response
          if (
            musicResult.status === "fulfilled" &&
            musicResult.value.status === 200
          ) {
            const fileQueryUrl = musicResult.value.data.file_query_url;
            setMusicGenerationQueryURL(fileQueryUrl);
          } else if (musicResult.status === "rejected") {
            console.error("Music generation failed:", musicResult.reason);
          }

          // Handle soundscape response
          if (
            soundscapeResult.status === "fulfilled" &&
            soundscapeResult.value.status === 200
          ) {
            const fileQueryUrl = soundscapeResult.value.data.file_url;
            setSoundscapeGenerationQueryURL(fileQueryUrl);
          } else if (soundscapeResult.status === "rejected") {
            console.error(
              "Soundscape generation failed:",
              soundscapeResult.reason,
            );
          }
        }
        setLoading(false);
      }
    };

    fetchAndStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptData]);

  // ***** Character Image Polling *****
  useEffect(() => {
    // only start polling when the data is available
    if (!scriptData || scriptData.length === 0) return;

    const fetchCharacterImagesState = async () => {
      try {
        const token = await getToken();
        const characterImagesResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/check-character-images-ready`,
          { storyId },
          {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          },
        );

        const imagesReady =
          characterImagesResponse.data.allCharacterImagesAreReady;
        setCharacterImagesReady(imagesReady);
      } catch (error) {
        console.error("Error fetching character images data:", error);
      }
    };

    if (!characterImagesReady && !characterIntervalIdRef.current) {
      characterIntervalIdRef.current = setInterval(() => {
        if (characterPollCountRef.current >= MAX_POLL_ATTEMPTS) {
          console.log(
            "Reached maximum retry attempts. Stopping character images polling.",
          );
          clearInterval(characterIntervalIdRef.current!);
          characterIntervalIdRef.current = null;
        } else {
          console.log(
            `Character images polling attempt ${characterPollCountRef.current + 1}`,
          );
          fetchCharacterImagesState();
          characterPollCountRef.current += 1;
        }
      }, 60000);
    }

    if (characterImagesReady) {
      if (characterIntervalIdRef.current) {
        clearInterval(characterIntervalIdRef.current);
        characterIntervalIdRef.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptData, characterImagesReady]);

  // ***** Scene Images Polling *****
  useEffect(() => {
    // only start polling when the data is available
    if (!scriptData || scriptData.length === 0) return;

    const fetchSceneImagesState = async () => {
      try {
        const token = await getToken();
        const sceneImagesResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/check-scene-images-ready`,
          { storyId },
          {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          },
        );

        const imagesReady = sceneImagesResponse.data.allSceneImagesAreReady;
        setSceneImagesReady(imagesReady);
      } catch (error) {
        console.error("Error fetching scene images data:", error);
      }
    };

    if (!sceneImagesReady && !sceneIntervalIdRef.current) {
      sceneIntervalIdRef.current = setInterval(() => {
        if (scenePollCountRef.current >= MAX_POLL_ATTEMPTS) {
          console.log(
            "Reached maximum retry attempts. Stopping scene images polling.",
          );
          clearInterval(sceneIntervalIdRef.current!);
          sceneIntervalIdRef.current = null;
        } else {
          console.log(
            `Scene images polling attempt ${scenePollCountRef.current + 1}`,
          );
          fetchSceneImagesState();
          scenePollCountRef.current += 1;
        }
      }, 60000);
    }

    if (sceneImagesReady) {
      if (sceneIntervalIdRef.current) {
        clearInterval(sceneIntervalIdRef.current);
        sceneIntervalIdRef.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptData, sceneImagesReady]);

  // ***** Soundscape Polling *****
  useEffect(() => {
    if (!storyId) return;
    const fetchSoundscapeGenerationState = async () => {
      try {
        const stateToken = (await getToken()) as string;

        const response = await checkAudioGenerationState({
          token: stateToken,
          storyId,
          audioType: "soundscape",
          queryUrl: soundscapeGenerationQueryURL,
        });

        if (response?.data.status === "DONE" && response.data.file_url) {
          // Stop the polling immediately before proceeding
          if (soundscapeIntervalIdRef.current) {
            console.log("Soundscape file ready: Clearing the interval");
            clearInterval(soundscapeIntervalIdRef.current);
            soundscapeIntervalIdRef.current = null;
          }
          const uploadToken = (await getToken()) as string;
          await uploadAudio({
            token: uploadToken,
            storyId,
            audioType: "soundscape",
            fileUrl: response.data.file_url,
          });

          setSoundscapeGenerationReady(true);
          console.log("Soundscape generation complete");
          return;
        }
      } catch (error) {
        console.error("Error checking soundscape generation status:", error);
      }
    };

    const clearPolling = () => {
      if (soundscapeIntervalIdRef.current) {
        clearInterval(soundscapeIntervalIdRef.current);
        soundscapeIntervalIdRef.current = null;
      }
    };

    const startPolling = () => {
      console.log("Starting polling for soundscape generation");
      soundscapePollAttemptsRef.current = 0;

      soundscapeIntervalIdRef.current = setInterval(() => {
        if (soundscapePollAttemptsRef.current >= MAX_POLL_ATTEMPTS) {
          console.log(
            "Soundscape maximum polling attempts reached. Stopping polling.",
          );
          clearPolling();
        } else {
          soundscapePollAttemptsRef.current += 1;
          console.log(
            `Soundscape polling attempt ${soundscapePollAttemptsRef.current}`,
          );
          fetchSoundscapeGenerationState();
        }
      }, 60000);
    };

    // Main logic
    if (soundscapeGenerationQueryURL) {
      if (!soundscapeIntervalIdRef.current) startPolling();
    } else {
      clearPolling();
    }

    return clearPolling;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soundscapeGenerationQueryURL]);

  // ***** Music Polling *****
  useEffect(() => {
    if (!storyId) return;
    const fetchMusicGenerationState = async () => {
      try {
        const stateToken = (await getToken()) as string;

        const response = await checkAudioGenerationState({
          token: stateToken,
          storyId,
          audioType: "music",
          queryUrl: musicGenerationQueryURL,
        });

        if (response?.data.status === "DONE" && response.data.file_url) {
          // Stop the polling immediately before proceeding
          if (musicIntervalIdRef.current) {
            console.log("Music file ready: Clearing the interval");
            clearInterval(musicIntervalIdRef.current);
            musicIntervalIdRef.current = null;
          }
          const uploadToken = (await getToken()) as string;
          await uploadAudio({
            token: uploadToken,
            storyId,
            audioType: "music",
            fileUrl: response.data.file_url,
          });

          setMusicGenerationReady(true);
          console.log("Music generation complete");
          return;
        }
      } catch (error) {
        console.error("Error checking music generation status:", error);
      }
    };

    const clearPolling = () => {
      if (musicIntervalIdRef.current) {
        clearInterval(musicIntervalIdRef.current);
        musicIntervalIdRef.current = null;
      }
    };

    const startPolling = () => {
      console.log("Starting polling for music generation");
      musicPollAttemptsRef.current = 0;

      musicIntervalIdRef.current = setInterval(() => {
        if (musicPollAttemptsRef.current >= MAX_POLL_ATTEMPTS) {
          console.log(
            "Music maximum polling attempts reached. Stopping polling.",
          );
          clearPolling();
        } else {
          musicPollAttemptsRef.current += 1;
          console.log(`Music polling attempt ${musicPollAttemptsRef.current}`);
          fetchMusicGenerationState();
        }
      }, 60000);
    };

    // Main logic
    if (musicGenerationQueryURL) {
      if (!musicIntervalIdRef.current) startPolling();
    } else {
      clearPolling();
    }

    return clearPolling;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [musicGenerationQueryURL]);

  return (
    <SectionWrapper
      canProceed={
        sceneImagesReady &&
        characterImagesReady &&
        musicGenerationReady &&
        soundscapeGenerationReady &&
        !loading
      }
      handleNext={() => navigate(`/story-viewer/${storyId}`)}
    >
      {scriptData.length === 0 ? (
        <GenerationStageAccordionWrapper
          title="Script"
          isEditingDisabled={isEditingDisabled}
          isLoading={loading}
        >
          <Typography variant="body2">
            The script is not available yet.
          </Typography>
        </GenerationStageAccordionWrapper>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Box sx={{ marginRight: "30px" }}>
              {sceneImagesReady ? (
                <Box>
                  <Typography sx={{ marginBottom: "20px" }}>
                    Scene images are generated!
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress size="30px" />
                  <Typography>
                    Scene images generation in progress...
                  </Typography>
                </Box>
              )}
              {characterImagesReady ? (
                <Box>
                  <Typography sx={{ marginBottom: "20px" }}>
                    Character poses and expression are generated!
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress size="30px" />
                  <Typography>
                    Character poses and expression image generation in
                    progress...
                  </Typography>
                </Box>
              )}
            </Box>
            <Box>
              {musicGenerationReady ? (
                <Box>
                  <Typography sx={{ marginBottom: "20px", color: "#fff" }}>
                    Music is successfully generated!
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress size="30px" />
                  <Typography sx={{ color: "#fff" }}>
                    Music generation in progress...
                  </Typography>
                </Box>
              )}
              {soundscapeGenerationReady ? (
                <Box>
                  <Typography sx={{ marginBottom: "20px", color: "#fff" }}>
                    Soundscape is successfully generated!
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress size="30px" />
                  <Typography sx={{ color: "#fff" }}>
                    Soundscape generation in progress...
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            scriptData.map((scene: any) => (
              <GenerationStageAccordionWrapper
                key={scene.sceneId}
                title={"Scene " + scene.sceneId}
                isEditingDisabled={isEditingDisabled}
                isLoading={false}
              >
                <ScriptLineDetails scene={scene} />
              </GenerationStageAccordionWrapper>
            ))
          }
        </>
      )}
    </SectionWrapper>
  );
};

export default ScriptStage;
