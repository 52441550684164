import { Box, Typography } from "@mui/material";
import LoadingStateIcon from "./story/accordion/LoadingStateIcon";

interface PublicViewStatusBarProps {
  storyGenerationStatus: undefined | "completed" | "inProgress";
  characterImagesCompleted: boolean;
  sceneImagesCompleted: boolean;
  musicGenerationCompleted: boolean;
  soundscapeGenerationCompleted: boolean;
}

const PublicViewStatusBar = ({
  storyGenerationStatus,
  characterImagesCompleted,
  sceneImagesCompleted,
  musicGenerationCompleted,
  soundscapeGenerationCompleted,
}: PublicViewStatusBarProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Typography> Story: </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <LoadingStateIcon
            isDataLoading={storyGenerationStatus === "inProgress"}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Typography>Character Images: </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <LoadingStateIcon isDataLoading={!characterImagesCompleted} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <Typography>Scene Images: </Typography>
          <LoadingStateIcon isDataLoading={!sceneImagesCompleted} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <Typography>Music: </Typography>
          <LoadingStateIcon isDataLoading={!musicGenerationCompleted} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <Typography>Soundscape: </Typography>
          <LoadingStateIcon isDataLoading={!soundscapeGenerationCompleted} />
        </Box>
      </Box>
    </Box>
  );
};

export default PublicViewStatusBar;
