import PersonIcon from "@mui/icons-material/Person";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import CreateIcon from "@mui/icons-material/Create";
import LogoutIcon from "@mui/icons-material/Logout";
// import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
export interface NavItemProps {
  text: string;
  path: string;
  icon: React.ReactNode;
}

export const topNavItems: NavItemProps[] = [
  { text: "Library", path: "/library", icon: <BookmarksIcon /> },
  { text: "New Story", path: "/new-story", icon: <CreateIcon /> },
  // {
  //   text: "Automated New Story",
  //   path: "/automated-new-story",
  //   icon: <AutoFixHighIcon />,
  // },
];

export const bottomNavItems: NavItemProps[] = [
  {
    text: "Profile",
    path: "/profile",
    icon: <PersonIcon />,
  },
  {
    text: "Logout",
    path: "/",
    icon: <LogoutIcon />,
  },
];
