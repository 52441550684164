import { CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

interface LoadingStateIconProps {
  isDataLoading: boolean;
  showTick?: boolean;
}

const LoadingStateIcon = ({
  isDataLoading,
  showTick,
}: LoadingStateIconProps) => {
  if (isDataLoading) {
    return <CircularProgress size={21} />;
  }
  if (showTick) {
    return (
      <CheckIcon
        sx={{
          fontSize: "21px",
          transition: "opacity 0.5s",
          opacity: 1,
        }}
      />
    );
  }
  return null;
};

export default LoadingStateIcon;
