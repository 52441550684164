import { useEffect, useRef } from "react";
import { Box, IconButton } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import SubtitleDisplay from "./SubtitleDisplay";
import useSceneParallaxAnimation from "../../hooks/useSceneParallaxAnimation";
import useCharacterParallax from "../../hooks/useCharacterParallaxAnimation";
import { useCharacters } from "../../hooks/useCharacters";
import { CharacterImageUrls, Emotion } from "../../types/characterTypes";
import { SceneImageUrls } from "../../types/sceneTypes";
import { ExtendedScriptLineWithSceneId } from "../../types/scriptTypes";

export const emotionTags = [
  "happy",
  "angry",
  "fear",
  "sad",
  "neutral",
] as const;

type StoryDisplayProps = {
  textContent: ExtendedScriptLineWithSceneId[];
  isPlaying: boolean;
  characterImageUrls?: CharacterImageUrls;
  sceneImageUrls?: SceneImageUrls;
  isMobile: boolean;
  isBackgroundVideo: boolean;
  setImageUrls: (value: {
    backgroundUrl: string;
    foregroundUrl: string;
  }) => void;
  imageUrls: {
    backgroundUrl: string;
    foregroundUrl: string;
  };
  setIsTransitioning: (value: boolean) => void;
  isTransitioning: boolean;
  isStoryFinished: boolean;
  setIsStoryFinished: (value: boolean) => void;
  currentLineIndex: number;
  setCurrentLineIndex: (value: number) => void;
  resetStoryDisplay: () => void;
};

const getExpressionForEmotion = (emotionText: string): Emotion => {
  const emotion = (emotionText || "").toLowerCase() as Emotion;
  return emotionTags.includes(emotion) ? emotion : "neutral";
};

const StoryDisplay = ({
  textContent,
  isPlaying,
  characterImageUrls,
  sceneImageUrls,
  isMobile,
  setIsTransitioning,
  isTransitioning,
  isBackgroundVideo,
  setImageUrls,
  imageUrls,
  isStoryFinished,
  setIsStoryFinished,
  currentLineIndex,
  setCurrentLineIndex,
  resetStoryDisplay,
}: StoryDisplayProps) => {
  const {
    characters,
    setCharacters,
    setUnavailableCharacters,
    updateCharactersWithImage,
  } = useCharacters();
  const sceneParallaxRefs = useSceneParallaxAnimation(isPlaying);
  const characterParallaxRefs = useCharacterParallax(characters, isPlaying);
  const charactersContainerRef = useRef<HTMLDivElement | null>(null);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const handleSceneChange = (sceneId: string) => {
    if (!sceneId) return;

    console.log("SCENE - CHANGE", sceneId);

    setIsTransitioning(true);

    setTimeout(() => {
      setCharacters([]);
      const backgroundUrl = isBackgroundVideo
        ? (sceneImageUrls && sceneImageUrls[sceneId]?.background_video) || ""
        : (sceneImageUrls && sceneImageUrls[sceneId]?.background) || "";

      const foregroundUrl =
        (sceneImageUrls && sceneImageUrls[sceneId]?.foreground) || "";
      setImageUrls({ backgroundUrl, foregroundUrl });

      setTimeout(() => setIsTransitioning(false), 500);
    }, 500);
  };

  const updateCharacterImages = (line: ExtendedScriptLineWithSceneId) => {
    const { characterName, emotion } = line;
    const trimmedCharacterName = characterName.trim().replace(/\s+/g, "");
    const newExpression = getExpressionForEmotion(emotion);
    try {
      const updatedCharacterImageUrls = characterImageUrls || {};
      const characterUrl =
        updatedCharacterImageUrls[trimmedCharacterName]?.[newExpression];
      if (!characterUrl) {
        throw new Error(
          `Image URL not found for character ${trimmedCharacterName} with expression ${newExpression}`,
        );
      }
      updateCharactersWithImage(
        trimmedCharacterName,
        characterUrl,
        newExpression,
      );
    } catch (error) {
      console.error(
        `Failed to fetch image for character ${trimmedCharacterName} with expression '${newExpression}':`,
        error,
      );
      setUnavailableCharacters((prevSet) =>
        new Set(prevSet).add(trimmedCharacterName),
      );
    }
  };

  const handleSubtitleTransition = () => {
    const currentLine = textContent[currentLineIndex];
    if (!isPlaying || !currentLine) return;

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }

    if (currentLine.lineType === "ToneChange") {
      setCurrentLineIndex(Math.min(currentLineIndex + 1, textContent.length));

      return;
    }

    if (currentLine.lineType === "SceneBuffer") {
      handleSceneChange(currentLine.sceneId);
    } else if (
      currentLine.lineType === "Dialogue" &&
      currentLine.characterName
    ) {
      updateCharacterImages(currentLine);
    }

    timeoutIdRef.current = setTimeout(() => {
      setCurrentLineIndex(Math.min(currentLineIndex + 1, textContent.length));
    }, currentLine.displayTime);
  };

  useEffect(() => {
    if (isPlaying) {
      handleSubtitleTransition();
    } else {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, currentLineIndex]);

  useEffect(() => {
    if (currentLineIndex >= textContent.length) {
      setIsStoryFinished(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLineIndex, textContent.length]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transformOrigin: "center",
        transform: isMobile ? `translateX(-32px) scale(1.1)` : `scale(1.4)`,
        "@media (max-width: 768px)": {
          transform: isMobile ? `translateX(-32px) scale(1)` : `scale(1.2)`,
        },
        "@media (max-width: 480px)": {
          transform: isMobile ? `translateX(-32px) scale(0.9)` : `scale(0.8)`,
        },
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "calc(100% - 64px)",
          aspectRatio: "16/9",
          maxHeight: "calc(100vh - 25px - 120px)",
          margin: isMobile ? "16px 0" : "16px auto",
          position: "relative",
          opacity: isTransitioning || isStoryFinished ? 0 : 1,
          transition: "opacity 0.5s ease-in-out",
          overflow: "visible",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            perspective: "600px",
            transformStyle: "preserve-3d",
          }}
        >
          {/* Background */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              transformStyle: "preserve-3d",
              transform: "translateZ(-300px)",
            }}
          >
            {imageUrls.backgroundUrl && (
              <>
                {isBackgroundVideo ? (
                  <Box
                    ref={
                      sceneParallaxRefs.background as React.RefObject<HTMLVideoElement>
                    }
                    component="video"
                    src={imageUrls.backgroundUrl}
                    autoPlay
                    loop
                    muted
                    playsInline
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      transform: "translate(-50%, -50%)",
                      zIndex: 0,
                      transition: "filter 0.5s ease-in-out",
                    }}
                  />
                ) : (
                  <Box
                    ref={
                      sceneParallaxRefs.background as React.RefObject<HTMLImageElement>
                    }
                    component="img"
                    src={imageUrls.backgroundUrl}
                    alt="background"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      transform: "translate(-50%, -50%)",
                      zIndex: 0,
                      overflow: "visible",
                    }}
                  />
                )}
              </>
            )}
          </Box>

          {/* Storyboard-Frame */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              transformStyle: "preserve-3d",
              transform: "translateZ(-150px)",
            }}
          >
            <Box
              ref={
                sceneParallaxRefs.storyboardFrame as React.RefObject<HTMLImageElement>
              }
              component="img"
              src="/images/storyboard-frame.png"
              alt="Storyboard Frame"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
                overflow: "visible",
              }}
            />
          </Box>

          {/* Characters */}
          <Box
            ref={charactersContainerRef}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "100%",
              height: "100%",
              pointerEvents: "none",
              transform: "translate(-50%, -50%) translateZ(-100px)",
              transformStyle: "preserve-3d",
              overflow: "visible",
            }}
          >
            {characters.length > 0 &&
              characters.map((character, index) => (
                <Box
                  key={character.name}
                  ref={(el: HTMLDivElement | null) => {
                    characterParallaxRefs.current[index] = el;
                  }}
                  component="img"
                  src={character.url}
                  alt={`${character.name} - ${character.expression}`}
                  sx={{
                    position: "absolute",
                    top: "55%",
                    left: `${character.position}%`,
                    width: "80%",
                    height: "auto",
                    objectFit: "contain",
                    transform: "translate(-50%, -50%)",
                    opacity: character.isVisible ? 1 : 0,
                    transition:
                      "opacity 0.3s ease-in-out, filter 0.3s ease-in-out",
                    filter: character.isSpeaking
                      ? "none"
                      : "blur(2px) brightness(0.7)",
                  }}
                  onError={(e) => {
                    console.error(
                      `Image failed to load for ${character.name} ${character.expression}:`,
                      e,
                    );
                    setCharacters((prev) =>
                      prev.filter((c) => c.name !== character.name),
                    );
                    setUnavailableCharacters((prevSet) => {
                      const newSet = new Set(prevSet);
                      newSet.add(character.name);
                      return newSet;
                    });
                  }}
                />
              ))}
          </Box>

          {/* Foreground */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              transformStyle: "preserve-3d",
              transform: "translateZ(-75px)",
              zIndex: 4,
            }}
          >
            {imageUrls.foregroundUrl && (
              <Box
                ref={
                  sceneParallaxRefs.foreground as React.RefObject<HTMLImageElement>
                }
                component="img"
                src={imageUrls.foregroundUrl}
                alt="foreground"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  transform: "translate(-50%, -50%)",
                  zIndex: 4,
                  overflow: "visible",
                }}
              />
            )}
          </Box>
        </Box>

        {/* Subtitles */}
        <Box
          sx={{
            position: "absolute",
            top: isMobile ? "20%" : 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            zIndex: 9999,
          }}
        >
          <SubtitleDisplay currentLine={textContent[currentLineIndex]} />
        </Box>
      </Box>

      {/* Replay Button */}
      {isStoryFinished && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 10000,
            transition: "opacity 0.5s ease-in-out",
            opacity: isStoryFinished ? 1 : 0,
          }}
        >
          <IconButton
            onClick={() => {
              resetStoryDisplay();
              setIsStoryFinished(false);
            }}
            sx={{
              backgroundColor: "none",
              color: "white",
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              border: "5px solid white",
            }}
          >
            <ReplayIcon sx={{ fontSize: 50 }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default StoryDisplay;
