import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import PageLayout from "../components/layout/PageLayout";
import ActionButton from "../components/profile/ActionButton";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { useStoryGenerationContext } from "../context/StoryGenerationContext";
import StoryDetails from "../components/story/StoryDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StoryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { getToken } = useAuth();
  const { storyGenerationState, setEntireStoryGenerationState } =
    useStoryGenerationContext();

  const { title, storyId, backgroundUrl } = location.state || {};

  const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const isTablet = useMediaQuery(theme.breakpoints.between("mobile", "tablet"));

  const [sections, setSections] = useState([""]);

  const [showStoryDetails, setShowStoryDetails] = useState(false);

  const [storyDescription, setStoryDescription] = useState("");

  const fetchStoryDetails = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/stories/${storyId}/sections`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      if (response.data) {
        setEntireStoryGenerationState(response.data);
        setSections(Object.keys(response.data));
      }
    } catch (error) {
      console.error(
        "Something went wrong while fetching story details!",
        error,
      );
    }
  };

  const fetchStoryDescription = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/stories/${storyId}/input-prompt`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      if (response.data) {
        setStoryDescription(response.data);
      }
    } catch (error) {
      console.error("Something went wrong while fetching input prompt!", error);
    }
  };

  useEffect(() => {
    fetchStoryDetails();
    fetchStoryDescription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout title="Library" subtitle={title}>
      {storyGenerationState ? (
        <Box
          sx={{ marginTop: "16px", marginLeft: "16px", marginRight: "24px" }}
        >
          <Box
            sx={{
              position: "relative",
              width: "calc(100% - 32px)",
              height: isMobile ? "300px" : isTablet ? "400px" : "600px",
              backgroundImage: `url(${backgroundUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "top center",
              borderRadius: "8px",
              overflow: "hidden",
              marginTop: "16px",
              marginLeft: "16px",
              marginRight: "16px",
              padding: "16px",
              display: "flex",
              alignItems: "center",
              color: "white",
              "::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "linear-gradient(to right, black, transparent)",
                borderRadius: "8px",
                border: "3px solid black",
                zIndex: 1,
              },
            }}
          >
            <Box
              sx={{
                position: "relative",
                zIndex: 2,
                display: "flex",
                flexDirection: "column",
                width: isMobile ? "100%" : "60%",
                height: "100%",
                paddingTop: "30px",
              }}
            >
              <Typography variant={isMobile ? "h4" : "h3"}>{title}</Typography>

              {showStoryDetails ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  {storyGenerationState.data.length !== 0 ? (
                    <>
                      <Box
                        sx={{
                          flexGrow: 1,
                          overflow: "hidden",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <StoryDetails
                          currentStep={sections.length}
                          sections={sections}
                        />
                      </Box>
                      <IconButton
                        sx={{
                          width: "70px",
                          height: "35px",
                          borderRadius: "15px",
                          backgroundColor: "rgba(102, 102, 102, 0.4)",
                          marginTop: "10px",
                        }}
                        onClick={() => setShowStoryDetails(false)}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </>
                  ) : (
                    <Box sx={{ textAlign: "center" }}>
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              ) : (
                <Box>
                  {storyDescription ? (
                    <Typography sx={{ marginTop: "30px" }}>
                      {storyDescription}
                    </Typography>
                  ) : (
                    <>
                      <CircularProgress />
                    </>
                  )}

                  <Box sx={{ display: "flex", gap: "16px", marginTop: "20px" }}>
                    <ActionButton
                      color="green"
                      buttonText="Play"
                      onClick={() => navigate(`/story-viewer/${storyId}`)}
                    />
                    <ActionButton
                      color="green"
                      buttonText="View"
                      onClick={() => setShowStoryDetails(true)}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ textAlign: "center", marginTop: "80px" }}>
          <CircularProgress />
        </Box>
      )}
    </PageLayout>
  );
};

export default StoryPage;
