import { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import GenerationStageAccordionWrapper from "../GenerationStageAccordionWrapper";
import SectionWrapper from "./SectionWrapper";
import { useStoryGenerationContext } from "../../../../context/StoryGenerationContext";
import { Scene } from "../../../../types/sceneTypes";
import startPoseAndExpressionGeneration from "../../../../utils/startPoseAndExpressionGeneration";
import startSceneImagesGeneration from "../../../../utils/startSceneImagesGeneration";
import SceneDetails from "../../../SceneDetails";
import ActionButton from "../../../profile/ActionButton";
import { Character } from "../../../../types/characterTypes";
import getLocations from "../../../../utils/getLocations";

interface ScenesStageProps {
  isEditingDisabled: boolean;
  handleNext?: () => Promise<void>;
  storyId?: string;
  setAlert?: (alertMsg: string) => void;
}

const ScenesStage = ({
  handleNext,
  storyId,
  isEditingDisabled,
  setAlert,
}: ScenesStageProps) => {
  const { getToken } = useAuth();
  const { storyGenerationState, updateStoryGenerationState } =
    useStoryGenerationContext();

  const [loading, setLoading] = useState(true);

  const [editedScenes, setEditedScenes] = useState<Scene[]>([]);
  const [characterNames, setCharacterNames] = useState<string[]>([]);
  const [locationSelect, setLocationSelect] = useState<string[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scenes =
    storyGenerationState?.data?.find((state) => state.section === "Scenes")
      ?.value || [];

  const [isBlueprintPollingFinished, setIsBlueprintPollingFinished] =
    useState(false);

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const pollCountRef = useRef(0);
  const MAX_POLL_ATTEMPTS = 20;
  const POLLING_INTERVAL = 40000;

  const checkCharacterBlueprints = useCallback(async () => {
    try {
      const token = await getToken();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/check-character-blueprints-ready`,
        { storyId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      if (response.data.missing_characters?.length === 0) {
        console.log(
          "response.data.missing_characters",
          response.data.missing_characters,
        );
        setIsBlueprintPollingFinished(true);
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error checking character blueprints:", error);
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getToken, storyId]);

  useEffect(() => {
    if (scenes?.length > 0) {
      setLoading(false);

      // create a copy of the data for editing
      setEditedScenes(() =>
        scenes.map((scene: Scene) => {
          // Use structuredClone to deep clone the character
          const clonedScene = structuredClone(scene);

          // Add or modify properties after cloning
          clonedScene.isEditing = false;

          return clonedScene;
        }),
      );

      // load locations for edit select
      const loadData = async () => {
        if (storyId) {
          const token = (await getToken()) as string;

          const result = await getLocations(token, storyId);
          setLocationSelect(result);
        }
      };

      loadData();

      // load characters from state for edit select
      const characters = storyGenerationState?.data?.find(
        (state) => state.section === "Characters",
      )?.value;

      setCharacterNames(() =>
        characters.map((character: Character) => character.scriptName),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenes, storyId]);

  useEffect(() => {
    if (!storyId || isBlueprintPollingFinished || scenes.length === 0) {
      return;
    }

    const startPolling = async () => {
      const isReady = await checkCharacterBlueprints();
      if (isReady) return;

      if (!intervalIdRef.current) {
        intervalIdRef.current = setInterval(async () => {
          if (pollCountRef.current >= MAX_POLL_ATTEMPTS) {
            console.log("Reached maximum retry attempts. Stopping polling.");
            setIsBlueprintPollingFinished(true);
            if (intervalIdRef.current) {
              clearInterval(intervalIdRef.current);
              intervalIdRef.current = null;
            }
            return;
          }

          console.log(`Polling attempt ${pollCountRef.current + 1}`);
          const isReady = await checkCharacterBlueprints();
          if (isReady) {
            if (intervalIdRef.current) {
              clearInterval(intervalIdRef.current);
              intervalIdRef.current = null;
            }
            return;
          }
          pollCountRef.current += 1;
        }, POLLING_INTERVAL);
      }
    };

    startPolling();

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [
    storyId,
    isBlueprintPollingFinished,
    checkCharacterBlueprints,
    scenes.length,
  ]);

  const handleToggleEdit = (sceneId: string) => {
    // Toggles edit on and off
    // when off shows the original scene
    setEditedScenes((editedScenes) =>
      editedScenes.map((editedScene) => {
        if (editedScene.sceneId === sceneId) {
          const originalScene = scenes.find(
            (b: Scene) => b.sceneId === sceneId,
          );

          if (!originalScene) {
            throw new Error(`Scene with id: ${sceneId} not found.`);
          }

          return {
            ...originalScene,
            isEditing: !editedScene.isEditing,
          };
        }
        return editedScene;
      }),
    );
  };

  const handleFieldChange = (
    sceneId: string,
    field: string,
    value: string | string[],
  ) => {
    setEditedScenes((prevScenes) =>
      prevScenes.map((scene) =>
        scene.sceneId === sceneId ? { ...scene, [field]: value } : scene,
      ),
    );
  };

  const handleSaveEditedScene = async (id: string) => {
    if (!editedScenes) {
      return;
    }

    setLoading(true);
    const token = await getToken();

    const editedScene = editedScenes.find((scene) => scene.sceneId === id);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-and-rerun-scene`,
        {
          storyId,
          scene: editedScene,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      // THIS WILL RETURN ALL OF THE SCENES
      const updated_scenes = response.data.updated_scenes;

      setEditedScenes(
        updated_scenes.map((scene: Scene) => ({
          ...scene,
          isEditing: false,
        })),
      );

      updateStoryGenerationState({ Scenes: updated_scenes });
    } catch (error) {
      setAlert?.("Failed to save the scene. Please try again.");

      console.error("Error saving scene:", error);
    } finally {
      setLoading(false);
    }
  };

  const hasEdits =
    (scenes && editedScenes?.some((scene) => scene.isEditing)) ?? false;

  return (
    <SectionWrapper
      canProceed={!hasEdits && isBlueprintPollingFinished && !loading}
      handleNext={
        // if handleNext is undefined NEXT button won't show
        // this is important when showing the previous steps
        handleNext && storyId
          ? async () => {
              const poseToken = (await getToken()) as string;
              startPoseAndExpressionGeneration({
                storyId,
                token: poseToken,
              });

              const sceneToken = (await getToken()) as string;
              startSceneImagesGeneration({
                storyId,
                token: sceneToken,
              });
              await handleNext();
            }
          : undefined
      }
    >
      {scenes.length === 0 ? (
        <GenerationStageAccordionWrapper
          title="Scenes"
          isEditingDisabled={isEditingDisabled}
          isLoading={loading}
        >
          <Typography variant="body2">No scenes available yet.</Typography>
        </GenerationStageAccordionWrapper>
      ) : (
        <>
          {/* This is a bid hacky, if handleNext is undefined, means the user should be at the next section 
        and looking at this generated data, no need to show blueprints state*/}
          {handleNext && (
            <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
              {!isBlueprintPollingFinished &&
              pollCountRef.current < MAX_POLL_ATTEMPTS ? (
                <>
                  <CircularProgress size={20} />
                  <Typography variant="body2" sx={{ color: "#fff", mt: 1 }}>
                    Waiting for character image generation...
                  </Typography>
                </>
              ) : pollCountRef.current >= MAX_POLL_ATTEMPTS ? (
                <Typography variant="body2" sx={{ color: "#fff" }}>
                  Character images took too long or failed! Please continue with
                  the story generation.
                </Typography>
              ) : null}
            </Box>
          )}

          {editedScenes?.map((scene: Scene) => (
            <GenerationStageAccordionWrapper
              key={scene.sceneId}
              title={"Scene " + scene.sceneId}
              isEditingDisabled={isEditingDisabled}
              isEditing={scene.isEditing}
              isLoading={loading}
              toggleEdit={() => {
                handleToggleEdit(scene.sceneId);
              }}
              onSave={() => {
                handleSaveEditedScene(scene.sceneId);
              }}
            >
              {scene.isEditing ? (
                <Box sx={{ textAlign: "left" }}>
                  <TextField
                    label="Description"
                    value={scene.description}
                    onChange={(e) =>
                      handleFieldChange(
                        scene.sceneId,
                        "description",
                        e.target.value,
                      )
                    }
                    fullWidth
                    multiline
                    sx={{ mb: 2 }}
                  />

                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Location</InputLabel>
                    <Select
                      value={scene.location || ""}
                      onChange={(e) =>
                        handleFieldChange(
                          scene.sceneId,
                          "location",
                          e.target.value,
                        )
                      }
                      label="Location"
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            backgroundColor: "black",
                            color: "white",
                          },
                        },
                      }}
                    >
                      {locationSelect.map((location) => (
                        <MenuItem key={location} value={location}>
                          {location}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Time of day"
                    value={scene.timeOfDay}
                    onChange={(e) =>
                      handleFieldChange(
                        scene.sceneId,
                        "timeOfDay",
                        e.target.value,
                      )
                    }
                    fullWidth
                    multiline
                    sx={{ mb: 2 }}
                  />
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Characters:
                  </Typography>
                  {scene.characters.map((character, index) => (
                    <Box key={index} sx={{ display: "flex", marginBottom: 2 }}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Character {index + 1}</InputLabel>
                        <Select
                          value={character}
                          onChange={(e) =>
                            handleFieldChange(
                              scene.sceneId,
                              "characters",
                              scene.characters.map((ch, i) =>
                                i === index ? e.target.value : ch,
                              ),
                            )
                          }
                          label={`Character ${index + 1}`}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                backgroundColor: "black",
                                color: "white",
                              },
                            },
                          }}
                        >
                          {characterNames.map((characterName) => (
                            <MenuItem key={characterName} value={characterName}>
                              {characterName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <ActionButton
                        onClick={() =>
                          handleFieldChange(
                            scene.sceneId,
                            "characters",
                            scene.characters.filter((_, i) => i !== index),
                          )
                        }
                        color="red"
                        buttonText="Remove"
                      />
                    </Box>
                  ))}
                  <ActionButton
                    onClick={() =>
                      handleFieldChange(scene.sceneId, "characters", [
                        ...scene.characters,
                        "",
                      ])
                    }
                    color="green"
                    buttonText="Add Character"
                  />

                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Instructions:
                  </Typography>
                  {scene.instructions.map((instruction, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        marginBottom: 2,
                      }}
                    >
                      <TextField
                        label={`Instruction ${index + 1}`}
                        value={instruction}
                        onChange={(e) =>
                          handleFieldChange(
                            scene.sceneId,
                            "instructions",
                            scene.instructions.map((instr, i) =>
                              i === index ? e.target.value : instr,
                            ),
                          )
                        }
                        fullWidth
                      />

                      <ActionButton
                        onClick={() =>
                          handleFieldChange(
                            scene.sceneId,
                            "instructions",
                            scene.instructions.filter((_, i) => i !== index),
                          )
                        }
                        color="red"
                        buttonText="Remove"
                      />
                    </Box>
                  ))}

                  <ActionButton
                    onClick={() =>
                      handleFieldChange(scene.sceneId, "instructions", [
                        ...scene.instructions,
                        "",
                      ])
                    }
                    color="green"
                    buttonText="Add Instruction"
                  />
                </Box>
              ) : (
                <SceneDetails scene={scene} />
              )}
            </GenerationStageAccordionWrapper>
          ))}
        </>
      )}
    </SectionWrapper>
  );
};

export default ScenesStage;
