import { ReactNode, MouseEvent } from "react";
import BaseAccordion from "./BaseAccordion";

export interface GenerationStageAccordionWrapperProps {
  children: ReactNode;
  title: string;
  isEditingDisabled: boolean;
  isLoading: boolean;
  isEditing?: boolean;
  onSave?: (e: MouseEvent<HTMLButtonElement>) => void;
  toggleEdit?: () => void;
}

const GenerationStageAccordionWrapper = (
  props: GenerationStageAccordionWrapperProps,
) => {
  return <BaseAccordion {...props} enableEditingFeatures />;
};

export default GenerationStageAccordionWrapper;
