import { Box, IconButton, Tooltip } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import ToggleButtonIcon from "@mui/icons-material/ToggleOn";
import ReplayIcon from "@mui/icons-material/Replay";
import { useEffect, useState } from "react";

let savedMusicTime = 0;
let savedSoundscapeTime = 0;

type ViewerControlsProps = {
  isPlaying: boolean;
  scriptOnlyMode: boolean;
  audioFiles: {
    music: string;
    soundscape: string;
  };
  setIsPlaying: (value: boolean) => void;
  onToggleScriptMode: () => void;
  isMobile: boolean;
  resetStoryDisplay: () => void;
  musicAudioRef: React.MutableRefObject<HTMLAudioElement | null>;
  soundscapeAudioRef: React.MutableRefObject<HTMLAudioElement | null>;
};

const ViewerControls = ({
  isPlaying,
  scriptOnlyMode,
  audioFiles,
  setIsPlaying,
  onToggleScriptMode,
  isMobile,
  resetStoryDisplay,
  musicAudioRef,
  soundscapeAudioRef,
}: ViewerControlsProps) => {
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    if (audioFiles.music && !musicAudioRef.current) {
      const musicAudio = new Audio(audioFiles.music);
      musicAudio.preload = "metadata";

      musicAudioRef.current = musicAudio;

      const setSavedTime = () => {
        if (savedMusicTime) {
          musicAudio.currentTime = savedMusicTime;
        }
      };

      if (musicAudio.readyState > 0) {
        setSavedTime();
      } else {
        musicAudio.addEventListener("loadedmetadata", setSavedTime);
      }
    }

    if (audioFiles.soundscape && !soundscapeAudioRef.current) {
      const soundscapeAudio = new Audio(audioFiles.soundscape);
      soundscapeAudio.preload = "metadata";

      soundscapeAudioRef.current = soundscapeAudio;

      const setSavedTime = () => {
        if (savedSoundscapeTime) {
          soundscapeAudio.currentTime = savedSoundscapeTime;
        }
      };

      if (soundscapeAudio.readyState > 0) {
        setSavedTime();
      } else {
        soundscapeAudio.addEventListener("loadedmetadata", setSavedTime);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioFiles]);

  useEffect(() => {
    return () => {
      if (musicAudioRef.current) {
        savedMusicTime = musicAudioRef.current.currentTime;
        musicAudioRef.current.pause();
        musicAudioRef.current = null;
      }
      if (soundscapeAudioRef.current) {
        savedSoundscapeTime = soundscapeAudioRef.current.currentTime;
        soundscapeAudioRef.current.pause();
        soundscapeAudioRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const playAudio = async () => {
    try {
      if (musicAudioRef.current && musicAudioRef.current.paused) {
        await musicAudioRef.current.play();
      }
      if (soundscapeAudioRef.current && soundscapeAudioRef.current.paused) {
        await soundscapeAudioRef.current.play();
      }
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  };

  const pauseAudio = () => {
    musicAudioRef.current?.pause();
    soundscapeAudioRef.current?.pause();
  };

  useEffect(() => {
    if (isPlaying) {
      playAudio();
    } else {
      pauseAudio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  const mute = () => {
    const newMutedState = !isMuted;
    setIsMuted(newMutedState);
    if (musicAudioRef.current) musicAudioRef.current.muted = newMutedState;
    if (soundscapeAudioRef.current) {
      soundscapeAudioRef.current.muted = newMutedState;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        gap: "16px",
      }}
    >
      {isPlaying ? (
        <IconButton
          onClick={() => setIsPlaying(false)}
          aria-label="Pause scrolling"
          disabled={scriptOnlyMode}
          sx={{
            color: "white",
            borderRadius: "5px",
            border: "1px solid white",
            width: isMobile ? "50px" : "40px",
            height: isMobile ? "50px" : "40px",
            backgroundColor: isMobile ? "transparent" : "#28D8A9",
          }}
        >
          <PauseIcon fontSize={isMobile ? "medium" : "medium"} />
        </IconButton>
      ) : (
        <IconButton
          onClick={() => setIsPlaying(true)}
          aria-label="Play scrolling"
          disabled={scriptOnlyMode}
          sx={{
            color: "white",
            borderRadius: "5px",
            border: "1px solid white",
            width: isMobile ? "50px" : "40px",
            height: isMobile ? "50px" : "40px",
            backgroundColor: "transparent",
          }}
        >
          <PlayArrowIcon fontSize={isMobile ? "large" : "medium"} />
        </IconButton>
      )}
      <IconButton
        onClick={mute}
        aria-label={isMuted ? "Unmute" : "Mute"}
        sx={{
          color: "white",
          borderRadius: "5px",
          border: "1px solid white",
          width: isMobile ? "50px" : "40px",
          height: isMobile ? "50px" : "40px",
        }}
      >
        {isMuted ? (
          <VolumeOffIcon
            sx={{ color: "red" }}
            fontSize={isMobile ? "large" : "medium"}
          />
        ) : (
          <VolumeUpIcon fontSize={isMobile ? "large" : "medium"} />
        )}
      </IconButton>
      <IconButton
        onClick={() => resetStoryDisplay()}
        aria-label="Replay"
        sx={{
          color: "white",
          borderRadius: "5px",
          border: "1px solid white",
          width: isMobile ? "50px" : "40px",
          height: isMobile ? "50px" : "40px",
        }}
      >
        <ReplayIcon fontSize={isMobile ? "large" : "medium"} />
      </IconButton>
      <Tooltip
        title={
          scriptOnlyMode
            ? "Switch to Cinematic Mode"
            : "Switch to Script-Only Mode"
        }
      >
        <IconButton
          onClick={() => {
            const newScriptMode = !scriptOnlyMode;
            onToggleScriptMode();
            setIsPlaying(!newScriptMode);
          }}
          aria-label={
            scriptOnlyMode
              ? "Disable Script Only Mode"
              : "Enable Script Only Mode"
          }
          aria-pressed={scriptOnlyMode}
          sx={{
            color: "white",
            backgroundColor: scriptOnlyMode ? "#28D8A9" : "transparent",
            borderRadius: "5px",
            border: scriptOnlyMode ? "1px solid #28D8A9" : "1px solid white",
            width: isMobile ? "50px" : "40px",
            height: isMobile ? "50px" : "40px",
            transition: "background-color 0.3s, color 0.3s",
          }}
        >
          <ToggleButtonIcon fontSize={isMobile ? "small" : "medium"} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ViewerControls;
