interface StoryOutlineDetailProps {
  children: React.ReactNode;
}

const StoryOutlineDetail = ({ children }: StoryOutlineDetailProps) => (
  <span style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}>
    {children}
  </span>
);

export default StoryOutlineDetail;
