import { Box, Typography } from "@mui/material";
import { formatFieldKey } from "../utils/formatFieldKey";
import { Scene } from "../types/sceneTypes";

interface SceneDetailsProps {
  scene: Scene;
}

const SceneDetails = ({ scene }: SceneDetailsProps) => {
  return (
    <Box
      sx={{
        color: "rgba(255, 255, 255, 0.8)",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        textAlign: "left",
      }}
    >
      <Typography variant="body2">
        <strong>{formatFieldKey("description")}:</strong> {scene.description}
      </Typography>
      <Typography variant="body2">
        <strong>{formatFieldKey("location")}:</strong> {scene.location}
      </Typography>
      <Typography variant="body2">
        <strong>{formatFieldKey("timeOfDay")}:</strong> {scene.timeOfDay}
      </Typography>
      <Typography variant="body2">
        <strong>{formatFieldKey("characters")}:</strong>{" "}
        {scene.characters?.join(", ")}
      </Typography>
      <Typography variant="body2">
        <strong>{formatFieldKey("instructions")}:</strong>
      </Typography>
      <ol
        style={{
          fontSize: "14px",
          color: "#fff",
          marginLeft: "20px",
        }}
      >
        {scene.instructions.map((instruction, i) => (
          <li key={i}>
            <Typography variant="body2">{instruction} </Typography>
          </li>
        ))}
      </ol>
    </Box>
  );
};

export default SceneDetails;
