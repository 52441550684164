import StoryDetailsAccordionWrapper from "../StoryDetailsAccordionWrapper";
import ScriptLineDetails from "../../../ScriptLineDetails";

interface ScriptAccordionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any[];
}

const ScriptAccordion = ({ content }: ScriptAccordionProps) => {
  return (
    <>
      {content.map((scene) => (
        <StoryDetailsAccordionWrapper
          key={scene.sceneId}
          title={"Scene " + scene.sceneId}
        >
          <ScriptLineDetails scene={scene} />
        </StoryDetailsAccordionWrapper>
      ))}
    </>
  );
};

export default ScriptAccordion;
