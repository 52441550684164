import StoryDetailsAccordionWrapper from "../StoryDetailsAccordionWrapper";

interface SettingAccordionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
}

const SettingAccordion = ({ content }: SettingAccordionProps) => {
  return (
    <>
      <StoryDetailsAccordionWrapper title="Setting">
        {content[0].setting}
      </StoryDetailsAccordionWrapper>
      <StoryDetailsAccordionWrapper title="Backdrop">
        {content[0].backdrop}
      </StoryDetailsAccordionWrapper>
    </>
  );
};

export default SettingAccordion;
