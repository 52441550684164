import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";

import { useStoryGenerationContext } from "../../context/StoryGenerationContext";
import GenresAccordion from "./accordion/storyDetails/GenresAccordion";
import ScriptAccordion from "./accordion/storyDetails/ScriptAccordion";
import ScenesAccordion from "./accordion/storyDetails/ScenesAccordion";
import CharactersAccordion from "./accordion/storyDetails/CharactersAccordion";
import StoryOutlinesAccordion from "./accordion/storyDetails/StoryOutlinesAccordion";
import SettingAccordion from "./accordion/storyDetails/SettingAccordion";
import TitleAccordion from "./accordion/storyDetails/TitleAccordion";
import splitWordAtCapitalLetter from "../../utils/splitWordAtCapitalLetter";

interface StoryDetailsProps {
  currentStep: number;
  sections: string[];
}

const sectionComponentMap: Record<string, React.ElementType> = {
  Title: TitleAccordion,
  Genres: GenresAccordion,
  Setting: SettingAccordion,
  Characters: CharactersAccordion,
  StoryOutline: StoryOutlinesAccordion,
  Scenes: ScenesAccordion,
  Script: ScriptAccordion,
};

const StoryDetails = ({ currentStep, sections }: StoryDetailsProps) => {
  const { storyGenerationState } = useStoryGenerationContext();

  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);

  const [content, setContent] = useState(() => {
    return storyGenerationState.data.find((state) => state.section === "Title")
      ?.value;
  });

  if (currentStep === -1 || sections.length === 1) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "8px",
        }}
      >
        {sections.map((section, index) => {
          const selectedValue = storyGenerationState?.data?.find(
            (state) => state.section === section,
          )?.value;

          return (
            <Box
              key={index}
              sx={{
                marginTop: "30px",
                marginBottom: "30px",
                flex: 1,
              }}
            >
              <IconButton
                onClick={() => {
                  setContent(selectedValue);
                  setSelectedSectionIndex(index);
                }}
                sx={{
                  width: "100%",
                  maxWidth: "120px",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    selectedSectionIndex === index
                      ? "linear-gradient(135deg, #28D8A9, #5FE692)"
                      : "rgba(102, 102, 102, 0.4)",
                  border:
                    selectedSectionIndex === index ? "2px solid white" : "none",
                  cursor: "pointer",
                  "&:hover": {
                    background:
                      "linear-gradient(135deg, rgba(40, 216, 169, 0.6), rgba(95, 230, 146, 0.6))",
                  },
                }}
              >
                <Typography sx={{ fontSize: "16px", whiteSpace: "nowrap" }}>
                  {splitWordAtCapitalLetter(section)}
                </Typography>
              </IconButton>
            </Box>
          );
        })}
      </Box>

      {content &&
        selectedSectionIndex !== undefined &&
        selectedSectionIndex !== null && (
          <Box sx={{ overflowY: "auto" }}>
            {React.createElement(
              sectionComponentMap[sections[selectedSectionIndex]],
              {
                content: content,
              },
            )}
          </Box>
        )}
    </>
  );
};

export default StoryDetails;
