import { Routes, Route, HashRouter, Navigate } from "react-router-dom";
import { SignedIn, SignedOut, useAuth } from "@clerk/clerk-react";

import Profile from "./pages/Profile";
import Home from "./pages/Home";
import NewStory from "./pages/NewStory";
import StoryPage from "./pages/StoryPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Library from "./pages/Library";
import "./styles/fonts.css";

import Navbar from "./components/navbar/Navbar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./styles/theme";

import RedirectToSignIn from "./components/auth/RedirectToSignIn";
import { StoryGenerationProvider } from "./context/StoryGenerationContext";
import StoryViewerPage from "./pages/StoryViewerPage";
import PublicStoryViewerPage from "./pages/PublicStoryViewerPage";
import AutomatedNewStory from "./pages/AutomatedNewStory";

function App() {
  const { isSignedIn } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={isSignedIn ? <Navigate to="/library" /> : <Home />}
          />

          <Route
            path="/"
            element={
              <>
                <SignedIn>
                  <Navbar />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            }
          >
            <Route
              path="profile"
              element={
                <SignedIn>
                  <Profile />
                </SignedIn>
              }
            />
            <Route
              path="library"
              element={
                <SignedIn>
                  <Library />
                </SignedIn>
              }
            />
            <Route
              path="new-story"
              element={
                <SignedIn>
                  <StoryGenerationProvider>
                    <NewStory />
                  </StoryGenerationProvider>
                </SignedIn>
              }
            />
            <Route
              path="automated-new-story"
              element={
                <SignedIn>
                  <StoryGenerationProvider>
                    <AutomatedNewStory />
                  </StoryGenerationProvider>
                </SignedIn>
              }
            />
            <Route
              path="story/:storyId"
              element={
                <SignedIn>
                  <StoryGenerationProvider>
                    <StoryPage />
                  </StoryGenerationProvider>
                </SignedIn>
              }
            />
            <Route
              path="story-viewer/:storyId"
              element={
                <SignedIn>
                  <StoryViewerPage />
                </SignedIn>
              }
            />
          </Route>
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />
          <Route
            path="auto-story-viewer/:storyId"
            element={<PublicStoryViewerPage />}
          />
        </Routes>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
