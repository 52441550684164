import { ReactNode } from "react";
import BaseAccordion from "./BaseAccordion";

export interface StoryDetailsAccordionWrapperProps {
  children: ReactNode;
  title: string;
}

const StoryDetailsAccordionWrapper = ({
  children,
  title,
}: StoryDetailsAccordionWrapperProps) => {
  return (
    <BaseAccordion title={title} isEditingDisabled={true} isLoading={false}>
      {children}
    </BaseAccordion>
  );
};

export default StoryDetailsAccordionWrapper;
