import StoryDetailsAccordionWrapper from "../StoryDetailsAccordionWrapper";

interface TitleAccordionProps {
  content: string;
}

const TitleAccordion = ({ content }: TitleAccordionProps) => {
  return (
    <StoryDetailsAccordionWrapper title="Title">
      {content}
    </StoryDetailsAccordionWrapper>
  );
};

export default TitleAccordion;
