import { Box, CircularProgress, Divider, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import StoryDisplay from "./StoryDisplay";
import ScrollTextBox from "./ScrollTextBox";
import ViewerControls from "./ViewerControls";
import MobilePortraitOverlay from "../MobilePortraitOverlay";
import { SceneImageUrls } from "../../types/sceneTypes";
import { CharacterImageUrls } from "../../types/characterTypes";
import { ExtendedScriptLineWithSceneId } from "../../types/scriptTypes";
import { useCharacters } from "../../hooks/useCharacters";

interface StoryViewerProps {
  audioFiles: { music: string; soundscape: string };
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  scriptOnlyMode: boolean;
  setScriptOnlyMode: (scriptOnlyMode: boolean) => void;
  scriptLines?: ExtendedScriptLineWithSceneId[];
  characterImageUrls?: CharacterImageUrls;
  sceneImageUrls?: SceneImageUrls;
}

const StoryViewer = ({
  scriptLines,
  characterImageUrls,
  sceneImageUrls,
  audioFiles,
  isPlaying,
  setIsPlaying,
  scriptOnlyMode,
  setScriptOnlyMode,
}: StoryViewerProps) => {
  const isMobileDevice = useMediaQuery("(max-width:920px)");
  const isPortrait = useMediaQuery("(orientation: portrait)");

  const isBackgroundVideo = false;
  const firstSceneId = sceneImageUrls && Object.keys(sceneImageUrls)[0];

  const initialImageUrls = firstSceneId
    ? isBackgroundVideo
      ? {
          backgroundUrl: sceneImageUrls[firstSceneId]?.background_video || "",
          foregroundUrl: sceneImageUrls[firstSceneId]?.foreground || "",
        }
      : {
          backgroundUrl: sceneImageUrls[firstSceneId].background || "",
          foregroundUrl: sceneImageUrls[firstSceneId].foreground || "",
        }
    : { backgroundUrl: "", foregroundUrl: "" };

  const [imageUrls, setImageUrls] = useState(initialImageUrls);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isStoryFinished, setIsStoryFinished] = useState(false);
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [resetScroll, setResetScroll] = useState(false);
  const musicAudioRef = useRef<HTMLAudioElement | null>(null);
  const soundscapeAudioRef = useRef<HTMLAudioElement | null>(null);
  const { setCharacters, setUnavailableCharacters } = useCharacters();

  useEffect(() => {
    if (isMobileDevice && isPortrait) {
      setScriptOnlyMode(true);
      setIsPlaying(false);
    } else if (isMobileDevice && !isPortrait) {
      setScriptOnlyMode(false);
    }
    if (!isMobileDevice && isPortrait) {
      setScriptOnlyMode(false);
    }
  }, [isPortrait, isMobileDevice, setScriptOnlyMode, setIsPlaying]);

  const resetStoryDisplay = () => {
    setImageUrls(initialImageUrls);
    setIsTransitioning(false);
    setIsStoryFinished(false);
    setCharacters([]);
    setUnavailableCharacters(new Set());
    setCurrentLineIndex(0);
    setResetScroll(true);
    if (musicAudioRef.current) {
      musicAudioRef.current.currentTime = 0;
      if (isPlaying) musicAudioRef.current.play();
    }
    if (soundscapeAudioRef.current) {
      soundscapeAudioRef.current.currentTime = 0;
      if (isPlaying) soundscapeAudioRef.current.play();
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        position: "relative",
        backgroundColor: "#121212",
        boxSizing: "border-box",
        padding: isMobileDevice ? "0 0 0 4px" : "80px",
        display: "flex",
        flexDirection: isMobileDevice ? "column" : "row",
        justifyContent: isMobileDevice ? "flex-start" : "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      {scriptLines ? (
        <>
          {/* Left Container */}
          <Box
            sx={{
              flexGrow: 1,
              width: isMobileDevice ? "100%" : undefined,
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: isMobileDevice ? "flex-start" : "center",
              paddingLeft: isMobileDevice ? "32px" : 0,
              marginRight: isMobileDevice ? 0 : "80px",
              position: "relative",
              opacity: scriptOnlyMode ? 0 : 1,
              transition: "opacity 0.3s ease-in-out",
            }}
          >
            <StoryDisplay
              textContent={scriptLines}
              isPlaying={isPlaying}
              characterImageUrls={characterImageUrls}
              sceneImageUrls={sceneImageUrls}
              isMobile={isMobileDevice}
              isBackgroundVideo={false}
              imageUrls={imageUrls}
              setImageUrls={setImageUrls}
              isTransitioning={isTransitioning}
              setIsTransitioning={setIsTransitioning}
              isStoryFinished={isStoryFinished}
              setIsStoryFinished={setIsStoryFinished}
              currentLineIndex={currentLineIndex}
              setCurrentLineIndex={setCurrentLineIndex}
              resetStoryDisplay={resetStoryDisplay}
            />
            {isMobileDevice && scriptOnlyMode && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#1e1e1e",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ScrollTextBox
                  textContent={scriptLines}
                  isPlaying={isPlaying}
                  resetScroll={resetScroll}
                  setResetScroll={setResetScroll}
                />
              </Box>
            )}
          </Box>

          {/* Desktop Layout for right container */}
          {!isMobileDevice && (
            <Box
              sx={{
                width: "400px",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "stretch",
              }}
            >
              <Divider
                orientation="vertical"
                sx={{
                  backgroundColor: "white",
                  width: "2px",
                  marginRight: "40px",
                }}
              />
              <Box
                sx={{
                  flex: 1,
                  height: "100%",
                  backgroundColor: "#1e1e1e",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: "80px",
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                    padding: "16px",
                  }}
                >
                  <ScrollTextBox
                    textContent={scriptLines}
                    isPlaying={isPlaying}
                    resetScroll={resetScroll}
                    setResetScroll={setResetScroll}
                  />
                </Box>
              </Box>
            </Box>
          )}

          {/* Mobile Portrait Overlay */}
          {isMobileDevice && isPortrait && <MobilePortraitOverlay />}

          {/* ViewerControls */}
          <Box
            sx={
              isMobileDevice
                ? {
                    position: "fixed",
                    right: "32px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    zIndex: 1000,
                  }
                : {
                    position: "absolute",
                    bottom: "115px",
                    right: "165px",
                    top: "auto",
                    zIndex: 1000,
                  }
            }
          >
            <ViewerControls
              isPlaying={isPlaying}
              scriptOnlyMode={scriptOnlyMode}
              audioFiles={audioFiles}
              setIsPlaying={setIsPlaying}
              onToggleScriptMode={() => setScriptOnlyMode(!scriptOnlyMode)}
              isMobile={isMobileDevice}
              resetStoryDisplay={resetStoryDisplay}
              musicAudioRef={musicAudioRef}
              soundscapeAudioRef={soundscapeAudioRef}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default StoryViewer;
