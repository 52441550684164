import { Button } from "@mui/material";

interface ActionButtonProps {
  buttonText: string;
  onClick: () => Promise<void> | void;
  color: "red" | "green";
}

const ActionButton = ({ buttonText, onClick, color }: ActionButtonProps) => {
  const buttonColor =
    color === "red"
      ? "linear-gradient(90deg, #f44336 0%, #FF5E57 100%)"
      : color === "green"
        ? "linear-gradient(135deg, #28D8A9, #5FE692)"
        : null;

  const hoverColor =
    color === "red"
      ? "linear-gradient(90deg, #FF5E57 0%, #f44336 100%)"
      : color === "green"
        ? "linear-gradient(135deg, #5FE692, #28D8A9)"
        : null;
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      sx={{
        background: buttonColor,
        color: "#000",
        alignSelf: "center",
        height: "35px",
        borderRadius: "4px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        textTransform: "none",
        fontSize: "16px",
        "&:hover": {
          background: hoverColor,
          boxShadow: "0 6px 8px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      {buttonText}
    </Button>
  );
};

export default ActionButton;
