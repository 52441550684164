import StoryDetailsAccordion from "../StoryDetailsAccordionWrapper";
import CharacterDetails from "../../../CharacterDetails";

interface CharactersAccordionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any[];
}

const CharactersAccordion = ({ content }: CharactersAccordionProps) => {
  return (
    <>
      {content.map((character) => {
        return (
          <StoryDetailsAccordion
            key={character.scriptName}
            title={character.scriptName ? character.scriptName : "Character"}
          >
            <CharacterDetails character={character} />
          </StoryDetailsAccordion>
        );
      })}
    </>
  );
};

export default CharactersAccordion;
