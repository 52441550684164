import { useState, useEffect, ChangeEvent } from "react";
import { TextField, Typography } from "@mui/material";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import GenerationStageAccordionWrapper from "../GenerationStageAccordionWrapper";
import { useStoryGenerationContext } from "../../../../context/StoryGenerationContext";
import SectionWrapper from "./SectionWrapper";
import { StoryOutline } from "../../../../types/storyOutlineTypes";
import StoryOutlineDetail from "../../../StoryOutlineDetail";

interface StoryBeatsStageProps {
  isEditingDisabled: boolean;
  setAlert?: (alertMsg: string) => void;
  handleNext?: () => Promise<void>;
  storyId?: string;
}

const StoryBeatsStage = ({
  handleNext,
  storyId,
  setAlert,
  isEditingDisabled,
}: StoryBeatsStageProps) => {
  const { getToken } = useAuth();
  const { storyGenerationState, updateStoryGenerationState } =
    useStoryGenerationContext();

  const [editableStoryBeats, setEditableStoryBeats] = useState<StoryOutline[]>([
    { title: "", num: 0, description: "", isEditing: false },
  ]);

  const [loading, setLoading] = useState(true);

  const storyBeats: StoryOutline[] = storyGenerationState?.data?.find(
    (state) => state.section === "StoryBeats",
  )?.value;

  useEffect(() => {
    if (storyBeats) {
      setEditableStoryBeats(() =>
        storyBeats.map((beat) => ({
          ...beat,
          isEditing: false,
        })),
      );
      setLoading(false);
    }
  }, [storyBeats]);

  const handleBeatDescriptionChange = (
    beatNum: number,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newDescription = e.target.value;
    setEditableStoryBeats((beats) =>
      beats.map((beat) =>
        beat.num === beatNum ? { ...beat, description: newDescription } : beat,
      ),
    );
  };

  const handleSaveEditedBeat = async (num: number) => {
    if (!editableStoryBeats) {
      return;
    }

    setLoading(true);
    const token = await getToken();

    // find the edited beat description
    const beatDescription = editableStoryBeats.find(
      (beat) => beat.num === num,
    )?.description;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-story-beat`,
        {
          storyId,
          description: beatDescription,
          num,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      // Update the state with the updated story beats returned from the server
      // THIS WILL RETURN ALL OF THE BEATS
      const updatedStoryBeats = response.data.updated_story_beats;
      setEditableStoryBeats(
        updatedStoryBeats.map((beat: StoryOutline) => ({
          ...beat,
          isEditing: false,
        })),
      );

      updateStoryGenerationState({ StoryBeats: updatedStoryBeats });
    } catch (error) {
      console.error("Error saving story beats:", error);
      setAlert?.("Failed to save story beat!");
    } finally {
      setLoading(false);
    }
  };

  const hasEdits =
    storyBeats &&
    editableStoryBeats.some(
      (beat) =>
        beat.description !==
        storyBeats.find(
          (originalStoryBeat) => originalStoryBeat.num === beat.num,
        )?.description,
    );

  const handleToggleEdit = (beatNum: number) => {
    // Sets isEditing to false and shows the original description
    setEditableStoryBeats((beats) =>
      beats.map((beat) => {
        if (beat.num === beatNum) {
          const originalBeat = storyBeats.find((b) => b.num === beatNum);

          if (!originalBeat) {
            throw new Error(
              `Original story beat with number ${beatNum} not found.`,
            );
          }
          return {
            ...beat,
            isEditing: !beat.isEditing,
            description: originalBeat.description,
          };
        }
        return beat;
      }),
    );
  };

  return (
    <SectionWrapper canProceed={!hasEdits && !loading} handleNext={handleNext}>
      {editableStoryBeats.map((beat) => {
        return (
          <GenerationStageAccordionWrapper
            key={beat.title}
            title={beat.title ? beat.title : "Story Beats"}
            isEditing={beat.isEditing as boolean}
            isLoading={loading}
            toggleEdit={() => handleToggleEdit(beat.num)}
            onSave={() => handleSaveEditedBeat(beat.num)}
            isEditingDisabled={isEditingDisabled}
          >
            {!beat.isEditing ? (
              <Typography
                variant="body2"
                sx={{
                  color: "rgba(255, 255, 255, 0.8)",
                  textAlign: "left",
                }}
              >
                <StoryOutlineDetail>Description:</StoryOutlineDetail>
                {beat.description}
              </Typography>
            ) : (
              <TextField
                label="Expanded Description"
                multiline
                rows={8}
                value={beat.description}
                onChange={(e) => handleBeatDescriptionChange(beat.num, e)}
                variant="outlined"
                fullWidth
                sx={{
                  marginBottom: 2,
                  label: { color: "white" },
                  backgroundColor: "rgba(40,40,40,0.5)",
                  borderRadius: "10px",
                  "& .MuiInputBase-input": {
                    textAlign: "left",
                    color: "white",
                  },
                }}
              />
            )}
          </GenerationStageAccordionWrapper>
        );
      })}
    </SectionWrapper>
  );
};

export default StoryBeatsStage;
