import { createContext, ReactNode, useContext, useState } from "react";

type StoryGenerationState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { section: string; value: any }[];
};

const StoryGenerationContext = createContext<{
  storyGenerationState: StoryGenerationState;
  updateStoryGenerationState: (newSection: Record<string, unknown>) => void;
  setEntireStoryGenerationState: (newState: StoryGenerationState) => void;
  clearStoryGenerationState: () => void;
}>({
  storyGenerationState: { data: [] },
  updateStoryGenerationState: () => {},
  setEntireStoryGenerationState: () => {},
  clearStoryGenerationState: () => {},
});

export const StoryGenerationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [storyGenerationState, setStoryGenerationState] =
    useState<StoryGenerationState>({ data: [] });

  const updateStoryGenerationState = (newSection: Record<string, unknown>) => {
    const sectionKey = Object.keys(newSection)[0];
    const sectionValue = newSection[sectionKey];

    setStoryGenerationState((prevState) => {
      const sectionExists = prevState.data.some(
        (section) => section.section === sectionKey,
      );

      const updatedData = sectionExists
        ? prevState.data.map((section) =>
            section.section === sectionKey
              ? { ...section, value: sectionValue }
              : section,
          )
        : [...prevState.data, { section: sectionKey, value: sectionValue }];

      return { ...prevState, data: updatedData };
    });
  };

  const setEntireStoryGenerationState = (newState: StoryGenerationState) => {
    if (!newState) return;
    const transformedData = Object.entries(newState).map(([key, value]) => ({
      section: key,
      value,
    }));
    setStoryGenerationState({ data: transformedData });
  };

  const clearStoryGenerationState = () => {
    setStoryGenerationState({ data: [] });
  };

  return (
    <StoryGenerationContext.Provider
      value={{
        storyGenerationState,
        updateStoryGenerationState,
        setEntireStoryGenerationState,
        clearStoryGenerationState,
      }}
    >
      {children}
    </StoryGenerationContext.Provider>
  );
};

export const useStoryGenerationContext = () =>
  useContext(StoryGenerationContext);
