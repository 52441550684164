import { Box, Typography } from "@mui/material";
import { ScriptLine, ScriptLinesWithSceneId } from "../types/scriptTypes";

interface ScriptLineDetailsProps {
  scene: ScriptLinesWithSceneId;
}

const ScriptLineDetails = ({ scene }: ScriptLineDetailsProps) => {
  return (
    <Box key={"Scene " + scene.sceneId} sx={{ marginBottom: "20px" }}>
      Scene {scene.sceneId}
      {scene.scriptLines.map((line: ScriptLine, index: number) => {
        if (line.lineType === "ToneChange") return null;
        return (
          <Box key={index} sx={{ marginBottom: "10px" }}>
            <Typography
              variant="body2"
              sx={{
                color: "rgba(255, 255, 255, 0.9)",
                fontWeight: "bold",
              }}
            >
              {line.characterName} ({line.lineType}):
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "rgba(255, 255, 255, 0.7)" }}
            >
              {line.text}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default ScriptLineDetails;
