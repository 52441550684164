import { useRef, useState } from "react";
import axios from "axios";

import { useAuth } from "@clerk/clerk-react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import AlertMessage from "../components/AlertMessage";
import StoryForm from "../components/StoryForm";
import { QRCodeSVG } from "qrcode.react";
import { useNavigate } from "react-router-dom";
import ActionButton from "../components/profile/ActionButton";

const AutomatedStoryGenerator = () => {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [storyId, setStoryId] = useState("");
  const [showContinueButton, setShowContinueButton] = useState(false);

  const [alert, setAlert] = useState("");

  const [showInitialSetupLoading, setShowInitialSetupLoading] = useState(false);
  const printRef = useRef<HTMLDivElement | null>(null);

  const handlePrint = () => {
    if (!printRef.current) return;

    const printContent = printRef.current.innerHTML;
    const printWindow = window.open("", "_blank");

    if (!printWindow) {
      setAlert("Popup blocked! Please allow popups for this site.");
      return;
    }

    // Set up the page size to 50mm x 30mm (width x height)
    printWindow.document.write(`
    <html>
      <head>
        <title>Print QR Code</title>
        <style>
          @page {
            size: 50mm 30mm; /* Set the page size (50mm x 30mm) */
            margin: 0; /* Remove margins */
            transform: rotate(0deg); /* Ensures no rotation */
            writing-mode: horizontal-tb; /* Explicitly set horizontal text direction */
          }
          body {
            width: 50mm; /* Set the body width to 50mm */
            height: 30mm; /* Set the body height to 30mm */
            margin: 0; /* Ensure there’s no margin */
            display: flex;
            align-items: center;
            transform: rotate(0deg); /* No rotation */
          }
          .qr-container {
            max-height: 30mm;
            display: flex;
          }
          .qr-img {
            width: 50%; /* Adjust the width of the QR code to fit the label */
            height: auto;
            margin-left: 10px
          }
          .title {
            margin-left: 10px;
            font-size: 12px; /* Smaller font size for the title */
          }
        </style>
      </head>
      <body>
        <div class="qr-container">
          ${printContent}
        </div>
        <script>
          window.onload = function() { 
            window.print();
            window.onafterprint = function() { window.close(); };
          };
        </script>
      </body>
    </html>
  `);

    printWindow.document.close();
  };

  const createInitialStory = async (formData: { prompt: string }) => {
    try {
      setShowInitialSetupLoading(true);
      const token = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-auto-generation`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      const { storyId: newStoryId, title: newTitle } = response.data.message;
      setStoryId(newStoryId);
      setTitle(newTitle);
      setShowInitialSetupLoading(false);
      setShowContinueButton(true);
    } catch (error) {
      setAlert("Failed to create initial story");
      setShowInitialSetupLoading(false);
    }
  };

  return (
    <Box className="flex flex-col items-center justify-center min-h-screen p-4">
      {/* Initial Setup Loading */}
      {showInitialSetupLoading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            paddingLeft: "16px",
          }}
        >
          <Typography sx={{ marginBottom: "20px", color: "#fff" }}>
            Initial Setup Loading...
          </Typography>
          <CircularProgress size="70px" />
        </Box>
      )}
      {/* Story Form */}
      {!showInitialSetupLoading && !alert && !showContinueButton && !title && (
        <Box
          sx={{
            width: "100%",
            marginTop: "80px",
            paddingLeft: { xs: "16px", sm: "240px" },
            textAlign: "center",
          }}
        >
          <Typography
            sx={{ marginTop: "30px", textAlign: "center", background: "red" }}
          >
            Please Note: THIS IS THE AUTO GENERATION FORM!!
          </Typography>
          <StoryForm handleSubmit={createInitialStory} />
        </Box>
      )}

      {/* Continue Button and Title */}
      {showContinueButton && title && (
        <Box
          sx={{
            textAlign: "center",
            paddingTop: "60px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: "400px",
              padding: "40px",
              borderRadius: "30px",
            }}
            ref={printRef}
            className="qr-container"
          >
            <QRCodeSVG
              className="qr-img"
              size={200}
              value={`${process.env.REACT_APP_URL}/#/auto-story-viewer/${storyId}`}
            />
            <Typography className="title">{title}</Typography>
          </Box>
          <Button onClick={handlePrint}>Print QR Code</Button>
          <ActionButton
            buttonText="View story"
            color="green"
            onClick={() => navigate(`/auto-story-viewer/${storyId}`)}
          />
        </Box>
      )}

      {/* Alert */}
      {alert && <AlertMessage severity="error" message={alert} />}
    </Box>
  );
};

export default AutomatedStoryGenerator;
