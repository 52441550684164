import StoryDetailsAccordionWrapper from "../StoryDetailsAccordionWrapper";

interface GenresAccordionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
}

const GenresAccordion = ({ content }: GenresAccordionProps) => {
  return (
    <>
      <StoryDetailsAccordionWrapper title="Style Genre">
        {content[0].styleGenre?.styleGenre}
      </StoryDetailsAccordionWrapper>
      <StoryDetailsAccordionWrapper title="External Content Genre">
        {content[0].externalContentGenre?.externalContentGenre}
      </StoryDetailsAccordionWrapper>
    </>
  );
};

export default GenresAccordion;
