import { Box, Button, Typography } from "@mui/material";
// import SearchBar from "../SearchBar";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";

type StickyHeaderProps = {
  title: string;
  subtitle?: string;
};

const StickyHeader = ({ title, subtitle }: StickyHeaderProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "sticky",
        top: 0,
        backgroundColor: "background.paper",
        zIndex: 4,
        height: "56px",
        padding: "14px 14px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {/* TODO: Make this work */}
        {/* <SearchBar /> */}
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Typography variant="h2">
          {title}
          {subtitle && (
            <>
              {" "}
              - <span dangerouslySetInnerHTML={{ __html: subtitle }} />
            </>
          )}
        </Typography>
      </Box>

      <Button
        sx={{ borderRadius: "50px" }}
        onClick={() => navigate("/profile")}
      >
        <PersonIcon sx={{ color: "white" }} />
      </Button>
    </Box>
  );
};

export default StickyHeader;
