import axios from "axios";

interface StartSceneImagesGenerationType {
  storyId: string;
  token: string;
}

const startSceneImagesGeneration = async ({
  storyId,
  token,
}: StartSceneImagesGenerationType) => {
  console.log("Start scene images generation!");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/start-scenes-image-generation`,
      { storyId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      },
    );
    console.log("Start scene images generation response: ", response);

    return response;
  } catch (error) {
    console.error("Failed to start scene generation");
  }
};

export default startSceneImagesGeneration;
