import axios from "axios";

interface StartMusicAndSoundscapeGenerationType {
  token: string;
  storyId: string;
}

const startMusicAndSoundscape = async ({
  token,
  storyId,
}: StartMusicAndSoundscapeGenerationType) => {
  try {
    const [musicResult, soundscapeResult] = await Promise.allSettled([
      axios.post(
        `${process.env.REACT_APP_API_URL}/generate-music`,
        { storyId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
      axios.post(
        `${process.env.REACT_APP_API_URL}/generate-soundscape`,
        { storyId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    ]);

    if (
      musicResult.status === "rejected" ||
      soundscapeResult.status === "rejected"
    ) {
      console.error("One or more audio generation tasks failed!");
    }

    console.log("Music and soundscape generation attempted.", {
      musicResult,
      soundscapeResult,
    });

    return { musicResult, soundscapeResult };
  } catch (error) {
    console.error("Unexpected error:", error);
  }
};

export default startMusicAndSoundscape;
