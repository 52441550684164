import { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import AlertMessage from "../components/AlertMessage";
import { CharacterImageUrls } from "../types/characterTypes";
import { SceneImageUrls } from "../types/sceneTypes";
import {
  ExtendedScriptLineWithSceneId,
  ScriptLinesWithSceneId,
} from "../types/scriptTypes";
import StoryViewer from "../components/viewer/StoryViewer";
import PublicViewStatusBar from "../components/PublicViewStatusBar";
import ActionButton from "../components/profile/ActionButton";

const PublicStoryViewerPage = () => {
  const [storyGenerationStatus, setStoryGenerationStatus] = useState<
    undefined | "completed" | "inProgress"
  >();
  const [musicGenerationCompleted, setMusicGenerationCompleted] =
    useState(false);
  const [soundscapeGenerationCompleted, setSoundscapeGenerationCompleted] =
    useState(false);
  const [characterImagesCompleted, setCharacterImagesCompleted] =
    useState(false);
  const [sceneImagesCompleted, setSceneImagesCompleted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [scriptOnlyMode, setScriptOnlyMode] = useState(false);
  const [alert, setAlert] = useState("");
  const [audioFiles, setAudioFiles] = useState({
    music: "",
    soundscape: "",
  });

  const { storyId } = useParams<{ storyId: string }>();

  const [scriptLines, setScriptLines] =
    useState<ExtendedScriptLineWithSceneId[]>();
  const [characterImageUrls, setCharacterImageUrls] =
    useState<CharacterImageUrls>();
  const [sceneImageUrls, setSceneImageUrls] = useState<SceneImageUrls>();

  const checkStoryStatus = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/check-auto-generation-status/${storyId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );

      if (data.story_generation_state) {
        setStoryGenerationStatus("completed");
      } else {
        setStoryGenerationStatus("inProgress");
      }
      if (data.character_images_state) {
        console.log("Character images are ready!");
        setCharacterImagesCompleted(true);
      }
      if (data.scene_images_state) {
        console.log("Scene images are ready!");
        setSceneImagesCompleted(true);
      }
      if (data.music_state) {
        setMusicGenerationCompleted(true);
        console.log("Music is ready!");
      }
      if (data.soundscape_state) {
        console.log("Soundscape is ready!");
        setSoundscapeGenerationCompleted(true);
      }
    } catch (error) {
      console.error("Error fetching story data", error);
      setAlert("Error fetching story data");
    }
  };

  const fetchStoryScript = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/story-script/${storyId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );

      const formattedData: ExtendedScriptLineWithSceneId[] =
        response.data.flatMap((scene: ScriptLinesWithSceneId) =>
          scene.scriptLines.map((line) => ({
            ...line,
            sceneId: scene.sceneId,
          })),
        );

      setScriptLines(formattedData);
    } catch (error) {
      console.error("Error fetching story data", error);
      setAlert("Error fetching story data");
    }
  };

  const fetchMusic = async () => {
    try {
      const musicPath = `generation_id_${storyId}/music.mp3`;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/get-audio`,
        {
          storyId,
          audioPath: musicPath,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );

      let alertsMsg = "";
      const result = response.data.audio_url;
      if (!result) alertsMsg += "No Music found! ";
      if (alertsMsg) setAlert(alertsMsg.trim());

      setAudioFiles((prev) => ({ ...prev, music: result }));
    } catch (error) {
      console.error("Error fetching music file", error);
      setAlert("Error fetching music file");
    }
  };

  const fetchSoundscape = async () => {
    try {
      const soundscapePath = `generation_id_${storyId}/soundscape.mp3`;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/get-audio`,
        {
          storyId,
          audioPath: soundscapePath,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );

      let alertsMsg = "";
      const result = response.data.audio_url;
      if (!result) alertsMsg += "No Soundscape found! ";
      if (alertsMsg) setAlert(alertsMsg.trim());

      setAudioFiles((prev) => ({ ...prev, soundscape: result }));
    } catch (error) {
      console.error("Error fetching music file", error);
      setAlert("Error fetching music file");
    }
  };

  const fetchSceneImages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/scene-images/${storyId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );

      setSceneImageUrls(response.data);
    } catch (error) {
      console.error("Error fetching scene images", error);
      setAlert("Error fetching scene images");
    }
  };

  const fetchCharacterImages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/character-images/${storyId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );

      setCharacterImageUrls(response.data);
    } catch (error) {
      console.error("Error fetching character images", error);
      setAlert("Error fetching character images");
    }
  };

  useEffect(() => {
    if (storyId) {
      checkStoryStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyId]);

  useEffect(() => {
    if (storyGenerationStatus === "completed") {
      fetchStoryScript();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyGenerationStatus]);

  useEffect(() => {
    if (musicGenerationCompleted) {
      fetchMusic();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [musicGenerationCompleted]);

  useEffect(() => {
    if (soundscapeGenerationCompleted) {
      fetchSoundscape();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soundscapeGenerationCompleted]);

  useEffect(() => {
    if (sceneImagesCompleted) {
      fetchSceneImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneImagesCompleted]);

  useEffect(() => {
    if (characterImagesCompleted) {
      fetchCharacterImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [characterImagesCompleted]);

  return (
    <Box>
      {alert && <AlertMessage severity="error" message={alert} />}

      <Box>
        {!storyGenerationStatus ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              marginTop: "40px",
            }}
          >
            <Typography>Let&apos;s check the state of your story</Typography>{" "}
            <Box>
              <CircularProgress />
            </Box>
          </Box>
        ) : storyGenerationStatus === "completed" ? (
          <>
            {storyGenerationStatus &&
            characterImagesCompleted &&
            sceneImagesCompleted &&
            musicGenerationCompleted &&
            soundscapeGenerationCompleted ? null : (
              <PublicViewStatusBar
                storyGenerationStatus={storyGenerationStatus}
                characterImagesCompleted={characterImagesCompleted}
                sceneImagesCompleted={sceneImagesCompleted}
                musicGenerationCompleted={musicGenerationCompleted}
                soundscapeGenerationCompleted={soundscapeGenerationCompleted}
              />
            )}
            <StoryViewer
              scriptLines={scriptLines}
              characterImageUrls={characterImageUrls}
              sceneImageUrls={sceneImageUrls}
              audioFiles={audioFiles}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              scriptOnlyMode={scriptOnlyMode}
              setScriptOnlyMode={setScriptOnlyMode}
            />
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{ maxWidth: "400px", textAlign: "center", marginTop: "40px" }}
            >
              <CircularProgress />
              <Typography>
                Your story is still being generated. Please check back in a few
                minutes by refreshing the page. It may take up to 20-30 minutes
                for the story to be fully generated.
              </Typography>
              <ActionButton
                buttonText="Refresh"
                color="green"
                onClick={() => window.location.reload()}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PublicStoryViewerPage;
