import { useState, useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { ExtendedScriptLineWithSceneId } from "../../types/scriptTypes";

type SubtitleDisplayProps = {
  currentLine: ExtendedScriptLineWithSceneId;
};

const SubtitleDisplay = ({ currentLine }: SubtitleDisplayProps) => {
  const [subtitleWidth, setSubtitleWidth] = useState("50%");
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  useEffect(() => {
    if (!currentLine) return;

    const { text = "" } = currentLine;
    const newWidth =
      text.length <= 50 ? "70%" : text.length <= 100 ? "60%" : "75%";
    setSubtitleWidth(newWidth);
  }, [currentLine]);

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const subtitleFontSize = useMemo(() => {
    return `${Math.min(Math.max(windowHeight * 0.015, 10), 10)}px`;
  }, [windowHeight]);

  if (currentLine?.lineType === "SceneBuffer") return null;

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: "15%",
        left: "50%",
        transform: "translateX(-50%)",
        pointerEvents: "none",
        zIndex: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: subtitleWidth,
        maxWidth: "50%",
      }}
      aria-live="polite"
    >
      {currentLine && (
        <Box
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.95)",
            color: "black",
            padding: "15px",
            border: "3px solid black",
            borderRadius: "5px",
            borderColor: "rgba(0, 0, 0, 1)",
            width: "115%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {/* Character Name Box */}
          {currentLine.characterName &&
            currentLine.lineType !== "Action" &&
            currentLine.characterName !== "DIRECTOR" && (
              <Box
                sx={{
                  position: "absolute",
                  top: "-40px",
                  left: "-4px",
                  backgroundColor: "rgba(0, 0, 0, 0.95)",
                  color: "white",
                  padding: "4px",
                  border: "3px solid black",
                  borderRadius: "4px",
                  textAlign: "centre",
                  pointerEvents: "none",
                  zIndex: 5,
                  width: "auto",
                  minWidth: "120px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "10px",
                    textTransform: "uppercase",
                  }}
                >
                  {currentLine.characterName}
                </Typography>
              </Box>
            )}

          <Typography
            sx={{
              fontSize: subtitleFontSize,
              color: "black",
              lineHeight: 1.75,
              fontStyle:
                currentLine.lineType === "Action" ? "italic" : "normal",
            }}
          >
            {currentLine.text}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SubtitleDisplay;
