import { Box } from "@mui/material";
import ActionButton from "../../../profile/ActionButton";

interface SectionWrapperProps {
  children: React.ReactNode;
  canProceed: boolean;
  handleNext?: () => Promise<void> | void;
}

const SectionWrapper = ({
  children,
  canProceed,
  handleNext,
}: SectionWrapperProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1000px",
        height: "100%",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          border: "2px solid rgba(217,217,217,0.28)",
          borderRadius: "12px",
          padding: "16px",
          backgroundColor: "transparent",
        }}
      >
        <Box
          sx={{
            maxHeight: "350px",
            overflowY: "auto",
          }}
        >
          {children}
        </Box>
      </Box>

      {handleNext && canProceed && (
        <ActionButton onClick={handleNext} buttonText="Next" color="green" />
      )}
    </Box>
  );
};

export default SectionWrapper;
